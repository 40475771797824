<template>
  <div>
    <h3>All entries</h3>
    <Modal v-bind:show-modal.sync="editingPerson" :modal-class="'modal-full-screen modal-dialog-centered'">
      <div class="modal-content">
        <div class="modal-body">
          <div class="modal-content overflow-auto">
            <div class="modal-header">
              <h5 class="modal-title">Change person</h5>
              <div class="col-auto p-0">

              </div>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-auto">
                  <div class="item-title">
                    Name
                  </div>
                  <div>
                    <div class="row">
                      <div class="col-auto">
                        <input type="text" class="form-control pe-0" v-model="personName"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-auto">
                  <div class="item-title">
                    Comments
                  </div>
                  <div>
                    <div class="row">
                      <div class="col-auto">
                        <input type="text" class="form-control pe-0" v-model="personComments"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-auto">
                  <span class="pb-3 mb-2 errors" v-if="errorMessage">{{ errorMessage }}</span>
                  <div class="item-title">
                    Extra
                  </div>
                  <div>
                    <div class="row">
                      <div class="col-auto">
                        <input type="text" class="form-control pe-0" v-model="personExtra"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-secondary"
                      data-dismiss="modal"
                      aria-label="Close"
                      @click="closePersonEditor()">
                Close
              </button>
              <button type="button" class="btn btn-primary" @click="savePersonChanges">Save changes</button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <Modal v-bind:show-modal.sync="addingOnePerson" :modal-class="'modal-full-screen modal-dialog-centered'">
      <div class="modal-content">
        <div class="modal-body">
          <div class="modal-content overflow-auto">
            <div class="modal-header">
              <h5 class="modal-title">Add new person</h5>
              <div class="col-auto p-0">

              </div>
            </div>
            <div class="modal-body">
              <div class="row mt-3">
                <span class="pb-3 errors" v-if="errorMessage">{{ errorMessage }}</span>
                <div class="col-auto">
                  <div class="item-title mb-2">
                    Name
                  </div>
                  <div>
                    <div class="row">
                      <div class="col-auto">
                        <input type="text" class="form-control pe-0" v-model="personName"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-auto">
                  <div class="item-title mb-2">
                    Comments
                  </div>
                  <div>
                    <div class="row">
                      <div class="col-auto">
                        <input type="text" class="form-control pe-0" v-model="personComments"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-auto">
                  <div class="item-title mb-2">
                    Extra
                  </div>
                  <div>
                    <div class="row">
                      <div class="col-auto">
                        <input type="text" class="form-control pe-0" v-model="personExtra"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary"
                      data-dismiss="modal"
                      aria-label="Close"
                      @click="closePersonAdding()">
                Close
              </button>
              <button type="button" class="btn btn-primary" @click="addOnePerson">Save changes</button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <Modal v-bind:show-modal.sync="massAddingPeople" :modal-class="'modal-full-screen modal-dialog-centered'">
      <div class="modal-content">
        <div class="modal-body">
          <div class="modal-content overflow-auto">
            <div class="modal-header">
              <h5 class="modal-title">Mass editor</h5>
              <div class="col-auto p-0">
              </div>
            </div>
            <div class="modal-body">
              <span class="mb-2 pb-3 errors" v-if="errorMessage">{{ errorMessage }}</span>
              <textarea class="form-control w-100" rows="10" v-model="massAddNames"></textarea>
              <p>Add each new entry onto a new row. The entry format is: Jane Jones, comment +3 OR Jane Jones +3, comment.</p>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary"
                      data-dismiss="modal"
                      aria-label="Close"
                      @click="closeMassAdding()">
                Close
              </button>
              <button type="button" class="btn btn-primary" @click="massAddPeople">Save changes</button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <Modal v-bind:show-modal.sync="movingPeople" :modal-class="'modal-full-screen modal-dialog-centered'">
      <div class="modal-content">
        <div class="modal-body">
          <div class="modal-content overflow-auto">
            <div class="modal-header">
              <h5 class="modal-title">Move people</h5>
              <div class="col-auto p-0">
              </div>
            </div>
            <div class="modal-body">
              <div class="text-danger" v-if="checkedPeople.length === people.length">You have selected everyone! Are you sure you want to do this?</div>
              <div class="mb-3">
                <label for="newunit">New unit</label>
                <select id="newunit" class="form-select" v-model="newUnitId" aria-label="Select unit"
                        @change="onChangeUnit($event)">
                  <option v-for="unit in units" :value="unit.id" v-bind:key="unit.id">{{ unit.name }}</option>
                </select>
              </div>
              <div class="mb-3">
                <label for="newgroup">New group</label>
                <select id="newgroup" class="form-select" v-model="newGroupId" aria-label="Select group">
                  <option value="" selected>No group</option>
                  <option v-for="group in unitGroups" :value="group.id" v-bind:key="group.id">{{ group.name }}</option>
                </select>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-secondary"
                      data-dismiss="modal"
                      aria-label="Close"
                      @click="closeMovingPeople()">
                Close
              </button>
              <button type="button" class="btn btn-primary" @click="movePeople">Save changes</button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <div v-if="event" class="d-flex flex-row mt-3">
      <div class="d-inline-flex">
        <input class="form-control" @input="clearChecked" type="text" v-model="textFilter" placeholder="Search"/>
      </div>
      <div class="ms-3 d-inline-flex" v-if="!onlyGuestlist">
        <select class="form-select" autocomplete="off" v-model="selectedProfile">
          <option selected="selected" :value="null">Edit profile</option>
          <option v-for="profile in profiles" :value="profile.id" v-bind:key="profile.id">{{ profile.name }}</option>
        </select>
        <button class="ms-2 btn btn-primary" v-on:click="setProfile()">Update</button>
      </div>

      <div class="ms-3 d-inline-flex align-middle" v-if="!onlyGuestlist">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" v-model="noProfile" value="" id="flexCheckDefault">
          <label class="form-check-label" for="flexCheckDefault">
            No profile
          </label>
        </div>
      </div>
      <div class="flex-grow-1"></div>
      <div class="align-self-end">
        <button class="btn btn-primary" v-on:click="changePerson()">
          <Icon name="edit" data-bs-toggle="tooltip" data-bs-placement="top" title="My tooltip."/>
        </button>
        <button class="ms-2 btn btn-primary" v-on:click="deleteSelected()">
          <Icon name="trash"/>
        </button>
        <button v-if="!onlyGuestlist" class="ms-2 btn btn-primary" v-on:click="moveSelected()">
          <Icon name="move"/>
        </button>
      </div>
    </div>
    <table class="table mt-3 people">
      <tbody>
      <tr>
        <td class="access-matrix align-middle" style="text-align: center">
          <input type="checkbox" v-on:click="masterCheckClicked"/>
        </td>
        <td class="access-matrix people subtitle2">Name</td>
        <td class="access-matrix people subtitle2">Extra</td>
        <td class="access-matrix people subtitle2" v-if="!onlyGuestlist">
          <select class="form-select" v-model="selectedUnit">
            <option :value="null">Unit</option>
            <option v-for="unit in units" v-bind:key="unit.id" :value="unit.id">{{ unit.name }}</option>
          </select>
        </td>
        <td v-if="!onlyGuestlist" class="access-matrix people subtitle2">
          <select class="form-select" v-model="selectedGroup">
            <option :value="null">Group</option>
            <option v-for="group in currentGroups" v-bind:key="group.id" :value="group.id">{{ group.name }}</option>
          </select>
        </td>
        <td class="access-matrix people subtitle2">Comments</td>
        <td class="access-matrix people subtitle2" v-if="!onlyGuestlist">Profile</td>
      </tr>
      <tr v-for="person in selectedPersons" v-bind:key="person.id">
        <td class="access-matrix align-middle" style="text-align: center"><input type="checkbox" :value="person.id"
                                                                                 v-model="checkedPeople"/></td>
        <td class="access-matrix">{{ person.name }}</td>
        <td class="text-center access-matrix">{{ person.guest_count > 1 ? person.guest_count - 1 : '' }}</td>
        <td v-if="!onlyGuestlist" class="access-matrix">{{ unit_names[person.unit_id] }}</td>
        <td v-if="!onlyGuestlist" class="access-matrix">{{
            person.group_id != null ? group_names[person.group_id] : ''
          }}
        </td>
        <td class="access-matrix">{{ person.comments }}</td>
        <td v-if="!onlyGuestlist" class="access-matrix">{{ profileName(person.profile_id) }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {
  addEventMassGuests,
  addGroupPerson,
  addUnitPerson,
  deletePeople,
  setProfileForPeople,
  updatePerson
} from "@/api";
import Icon from "@/components/Icon";
import Modal from "@/components/Modal";
import {Tooltip} from "bootstrap"

export default {
  name: "PeopleData",
  props: ['event', 'people', 'profiles', 'unit_names', 'group_names',
    'onlyGuestlist',
    'groupId', 'unitId', 'units'],
  components: {Icon, Modal},
  data() {
    return {
      errorMessage: null,
      selectedUnit: null,
      selectedGroup: null,
      noProfile: false,
      selectedProfile: null,
      checkedPeople: [],
      textFilter: '',
      editingPerson: false,
      addingOnePerson: false,
      massAddingPeople: false,
      moveUnit: null,
      moveGroup: null,
      personComments: '',
      personName: '',
      massAddNames: '',
      personExtra: 0,
      editedPerson: null,
      movingPeople: false,
      unitGroups: [],
      newUnitId: null,
      newGroupId: null,
    }
  },
  mounted() {

  },
  methods: {
    clearChecked() {
      this.checkedPeople = []
    },
    profileName(id) {
      if (id === null)
        return ''
      let profile = this.profiles.find(p => {
        return p.id === id
      })
      if (profile === undefined)
        return ''

      return profile.name
    },
    addNewPerson() {
      this.addingOnePerson = true
      this.errorMessage = null
    },
    openMassAddPeople() {
      this.massAddingPeople = true
      this.errorMessage = null
    },
    async massAddPeople() {
      await addEventMassGuests(this.event.id, this.unitId, this.groupId, this.massAddNames).then(addedPeople => {
        this.$emit("addedPeople", addedPeople)
        this.massAddNames = ''
        this.massAddingPeople = false
      }).catch(e => this.errorMessage = e.message)
    },
    changePerson() {
      if (this.checkedPeople.length > 1) {
        alert("You have selected too many people")
        return;
      }
      this.errorMessage = null
      this.editedPerson = this.people.find(p => p.id === this.checkedPeople[0])
      this.personName = this.editedPerson.name
      this.personComments = this.editedPerson.comments
      this.personExtra = this.editedPerson.guest_count - 1
      this.editingPerson = true
    },
    async savePersonChanges() {
      await updatePerson(this.event.id, this.editedPerson.id, {
        'name': this.personName,
        'comments': this.personComments,
        'extra': this.personExtra
      }).then(p => {
        this.$emit('updatedPerson', this.editedPerson.id, {
          'name': this.personName,
          'comments': this.personComments,
          'guest_count': parseInt(this.personExtra) + 1
        })
        this.editedPerson = null
        this.editingPerson = false
        this.checkedPeople = []
      })
        .catch(e => this.errorMessage = e.message)
    },
    async addOnePerson() {
      if (this.groupId) {
        let addedPerson = await addGroupPerson(this.event.id, this.unitId, this.groupId, {
          name: this.personName,
          comments: this.personComments,
          extra: this.personExtra
        }).then(p => this.addedPerson(p))
          .catch(e => this.errorMessage = e.message)
        this.$emit('addedPeople', [addedPerson])
      } else {
        await addUnitPerson(this.event.id, this.unitId,
          {
            name: this.personName,
            comments: this.personComments,
            extra: this.personExtra
          }).then(p => this.addedPerson(p))
          .catch(e => this.errorMessage = e.message)
      }
    },
    addedPerson(person) {
      this.addingOnePerson = false
      this.personName = ''
      this.personComments = ''
      this.personExtra = 0
      this.$emit('addedPeople', [person])
    },
    deleteSelected() {
      if (confirm("Are you sure you want to delete the selected people from the event?")) {
        deletePeople(this.event.id, {toDelete: this.checkedPeople})
        this.people = this.people.filter(p => !this.checkedPeople.includes(p.id))
      }
    },
    filterPersonGroupUnit(p) {
      if (this.selectedUnit === null && this.selectedGroup === null) {
        return true
      } else {
        if (p.unit_id !== null && p.unit_id === this.selectedUnit && this.selectedGroup === null)
          return true
        else if (this.selectedGroup !== null && p.group_id === this.selectedGroup) {
          return true
        }
      }
    },
    filterText(p) {
      if (p.comments && p.comments.toLowerCase().includes(this.textFilter.toLowerCase())) {
        return true
      }

      if (p.name.toLowerCase().includes(this.textFilter.toLowerCase())) {
        return true
      } else {
        return false
      }

      return false
    },
    filterNoProfile(p) {
      if (this.noProfile) {
        if (p.profile_id === null) {
          return true
        } else {
          return false
        }
      }
      return true
    },
    closePersonEditor() {
      this.editingPerson = false
      this.editedPerson = null
    },
    closePersonAdding() {
      this.addingOnePerson = false
    },
    closeMassAdding() {
      this.massAddingPeople = false
    },
    onChangeUnit(event) {
      let unitGroups = this.units.find(u => {
        return u.id == event.target.value
      })
      if (unitGroups) {
        this.unitGroups = unitGroups.groups
      }
    },
    closeMovingPeople() {
      this.movingPeople = false
    },
    moveSelected() {
      this.movingPeople = true
    },
    async movePeople() {
      if (this.newUnitId === null)
        return

      for (const p of this.checkedPeople) {
        await updatePerson(this.event.id, p, {'unitId': this.newUnitId, 'groupId': this.newGroupId})
        let updated_person = this.people.filter(person => p === person.id).at(0)
        updated_person.unit_id = this.newUnitId
        updated_person.group_id = this.newGroupId
      }
      this.checkedPeople = []
      this.newUnitId = null
      this.newGroupId = null

      this.closeMovingPeople()
    },
    async setProfile() {
      await setProfileForPeople(this.eventId, this.selectedProfile, this.checkedPeople)
      for (const p of this.checkedPeople) {
        let updated_person = this.people.filter(person => p === person.id).at(0)
        updated_person.profile_id = this.selectedProfile
      }
      this.checkedPeople = []
      this.$emit('updatedProfile', this.selectedProfile, this.checkedPeople)
    },
    masterCheckClicked(event) {
      if (event.target.checked) {
        this.checkedPeople = this.selectedPersons.map(p => p.id)
      } else {
        this.checkedPeople = []
      }
    },
  },
  computed: {
    selectedPersons: function () {
      return this.people.filter(p => {
        return this.filterPersonGroupUnit(p) && this.filterText(p) && this.filterNoProfile(p)
      })
    },
    currentGroups: function () {
      if (this.selectedUnit === null) {
        return []
      }
      return this.units.find(u => u.id === this.selectedUnit).groups
    }
  },
}
</script>
