<template>
  <div>
    <Navbar :event="event" :eventId="eventId" currentPage="Artists"/>
    <ArtistsMenu  v-if="event" active="schedule" :eventId="eventId" :event="event">
      <div v-if="event" class="d-flex flex-row mt-3">
        <div class="flex-grow-1">
          <Breadcrumbs :event="event" currentPageName="Schedule"/>
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <h2>Schedule</h2>
        <div>
          <button @click="addingStage = true" class="btn btn-primary">Add stage</button>
        </div>
      </div>

      <div v-if="schedules.length === 0" class="d-flex justify-content-center">
        No schedules created yet,&nbsp;
        <button @click="addingSchedule=true" class="btn btn-primary">Add a schedule</button>
      </div>
      <div v-else>
        <div v-for="schedule in schedules" v-bind:key="'schedule-'+schedule.id">
          <div class="mt-3 row">
            <h4>{{ schedule.name }}</h4>
            <div class="row mb-3">
              <div class="col">
                <button class="btn btn-primary" @click="addArtist(schedule.id)">Add artist</button>
              </div>
            </div>
            <template v-for="stage in stages">
              <div v-bind:key="'stage-'+stage.id" class="col-md-4 mt-2 mb-5">
                <strong class="mb-5">{{ stage.name }}</strong>
                <br/>
                <template v-for="a in schedule.artists">
                  <div v-if="a.stage_id === stage.id" v-bind:key="'a-' + a.id"
                       @click="startEditingArtistSchedule(schedule, a)">
                    {{ a.start_time }}&#8212;{{ a.end_time }} {{ getArtist(a.artist_id).name }}
                  </div>
                  <div v-if="editingArtistSchedule === a && a.stage_id === stage.id" v-bind:key="'editing-a' + a.id"
                       class="">
                    <button class="btn btn-primary" @click="openEditArtistSchedule(a)">
                      <Icon name="edit" width="15" height="15"/>
                    </button>
                    <button class="ms-2 btn btn-primary" @click="deleteArtistSchedule(a)">
                      <Icon name="trash" width="15" height="15"/>
                    </button>
                    <button class="ms-2 btn btn-primary" @click="cancelEditingArtistSchedule(a)">
                      <Icon name="close" width="15" height="15"/>
                    </button>
                  </div>
                </template>
              </div>

            </template>
          </div>
          <hr/>
        </div>
      </div>

      <div class="d-flex justify-content-center">
        <button v-if="schedules.length > 0" @click="addingSchedule=true" class="me-2 btn btn-primary">Add a new schedule
        </button>
        <button v-if="event.schedule_published" @click="unpublishSchedule()" class="btn btn-primary">Unpublish schedule</button>
        <button v-else class="btn btn-primary" @click="publishSchedule()">Publish schedule</button>
      </div>
    </ArtistsMenu>
    <Modal v-bind:show-modal.sync="addingSchedule" :modal-class="'modal-full-screen modal-dialog-centered'">
      <div class="modal-content">
        <div class="modal-body">
          <div class="modal-content overflow-auto">
            <div class="modal-header">
              <h5 class="modal-title">Add a schedule</h5>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-auto">
                  <div class="item-title">
                    Name
                  </div>
                  <div>
                    <div class="row">
                      <div class="col-auto">
                        <input type="text" required class="form-control pe-0" v-model="name"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-auto">
                  <div class="item-title">
                    Date
                  </div>
                  <div>
                    <div class="row">
                      <div class="col-auto">
                        <input type="date" required class="form-control pe-0" v-model="date"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="item-title">
                  Start time
                </div>
                <div class="col-auto">
                  <div>
                    <div class="row">
                      <div class="col-auto">
                        <input type="time" required class="form-control pe-0" v-model="startTime"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="item-title">
                  End time
                </div>
                <div class="col-auto">
                  <div>
                    <div class="row">
                      <div class="col-auto">
                        <input type="time" required class="form-control pe-0" v-model="endTime"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-secondary"
                      data-dismiss="modal"
                      aria-label="Close"
                      @click="cancelAddingSchedule()">
                Close
              </button>
              <button type="button" class="btn btn-primary" @click="addSchedule()">Save changes</button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <Modal v-bind:show-modal.sync="addingArtist" :modal-class="'modal-full-screen modal-dialog-centered'">
      <div class="modal-content">
        <div class="modal-body">
          <form v-on:submit.prevent="saveArtist">
            <div class="modal-content overflow-auto">
              <div class="modal-header">
                <h5 class="modal-title">Add an artist</h5>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-12">
                    <div class="item-title">
                      Artist
                    </div>
                    <div>
                      <div class="row">
                        <div class="col-auto">
                          <select required class="form-control pe-0" v-model="selectedArtistId">
                            <option :value="null" disabled="disabled">Select artist</option>
                            <option v-for="artist in artists" :value="artist.id" v-bind:key="'artist-'+artist.id">
                              {{ artist.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12">
                    <div class="item-title">
                      Stage
                    </div>
                    <div>
                      <div class="row">
                        <div class="col-auto">
                          <select required class="form-control pe-0" v-model="selectedStageId">
                            <option :value="null" disabled="disabled">Select stage</option>
                            <option v-for="stage in stages" :value="stage.id" v-bind:key="'stage-'+stage.id">
                              {{ stage.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="item-title">
                    Start time
                  </div>
                  <div class="col-auto">
                    <div>
                      <div class="row">
                        <div class="col">
                          <input required type="time" class="form-control pe-0" v-model="artistStartTime"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="item-title">
                    End time
                  </div>
                  <div class="col-auto">
                    <div>
                      <div class="row">
                        <div class="col-auto">
                          <input required type="time" class="form-control pe-0" v-model="artistEndTime"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="submit" class="btn btn-secondary"
                        data-dismiss="modal"
                        aria-label="Close"
                        @click="cancelAddingArtist()">
                  Close
                </button>
                <button type="submit" class="btn btn-primary">Save changes</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
    <Modal v-bind:show-modal.sync="openEditingArtistSchedule" :modal-class="'modal-full-screen modal-dialog-centered'">
      <div class="modal-content" v-if="editingArtistSchedule">
        <div class="modal-body">
          <div class="modal-content overflow-auto">
            <div class="modal-header">
              <h5 class="modal-title">Edit artist schedule</h5>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <div class="item-title">
                    Artist
                  </div>
                  <div>
                    <div class="row">
                      <div class="col-auto">
                        {{ getArtist(editingArtistSchedule.artist_id).name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="item-title">
                  Start time
                </div>
                <div class="col-auto">
                  <div>
                    <div class="row">
                      <div class="col">
                        <input required type="time" class="form-control pe-0"
                               v-model="editingStartTime"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="item-title">
                  End time
                </div>
                <div class="col-auto">
                  <div>
                    <div class="row">
                      <div class="col-auto">
                        <input required type="time" class="form-control pe-0" v-model="editingEndTime"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn btn-secondary"
                      data-dismiss="modal"
                      aria-label="Close"
                      @click="cancelEditingArtistSchedule()">
                Close
              </button>
              <button type="submit" @click="saveArtistSchedule" class="btn btn-primary">Save changes</button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <Modal v-bind:show-modal.sync="addingStage" :modal-class="'modal-full-screen modal-dialog-centered'">
      <div class="modal-content">
        <div class="modal-body">
          <div class="modal-content overflow-auto">
            <div class="modal-header">
              <h5 class="modal-title">Add stage</h5>
            </div>
            <div class="modal-body">
              <div class="row mt-3">
                <div class="col-auto">
                  <div class="item-title mb-2">
                    Name
                  </div>
                  <div>
                    <div class="row">
                      <div class="col-auto">
                        <input type="text" class="form-control pe-0" v-model="stageName"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary"
                      data-dismiss="modal"
                      aria-label="Close"
                      @click="addingStage = false">
                Cancel
              </button>
              <button type="button" class="btn btn-primary" @click="saveStage">Save</button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import Navbar from '@/components/Navbar.vue'
import {
  addEventSchedule, addStage, deleteArtistPerformance,
  getEventArtists,
  getEventSchedules,
  getStages,
  getStagesTimetable,
  getTimetable, publishSchedules, saveArtistScheduleBooking,
  saveTimetableItem, unpublishSchedules, updateArtistSchedule
} from "@/artists_api";
import {getEvent, getEventData} from "@/api";
import Breadcrumbs from "@/components/Breadcrumbs";
import ArtistsMenu from "@/components/ArtistsMenu";
import Modal from "@/components/Modal";
import store from "@/store";
import Icon from "@/components/Icon";

export default {
  name: 'Artists',
  components: {
    Navbar, Breadcrumbs, ArtistsMenu, Modal, Icon
  },
  props: ['eventId'],
  data() {
    return {
      active: '',
      name: '',
      date: null,
      startTime: null,
      endTime: null,
      schedules: [],
      artists: [],
      selectedArtistId: null,
      event: null,
      stages: [],
      selectedScheduleId: null,
      selectedStageId: null,
      selectedArtist: null,
      artistStartTime: null,
      artistEndTime: null,
      addingArtist: false,
      addingSchedule: false,
      savingArtist: false,
      editingArtistSchedule: null,
      openEditingArtistSchedule: false,
      editingSchedule: null,
      editingStartTime: null,
      editingEndTime: null,

      addingStage: false,
      stageName: ''
    }
  },
  methods: {
    unpublishSchedule() {
      unpublishSchedules(this.eventId).then(() => {
        this.event.schedule_published = false
        store.addAlert("success", "Schedule unpublished")
      })
    },
    publishSchedule() {
      publishSchedules(this.eventId).then(() => {
        this.event.schedule_published = true
        store.addAlert("success", "Schedule published")
      })
    },
    startEditingArtistSchedule(schedule, a) {
      this.editingArtistSchedule = a
      this.editingSchedule = schedule
    },
    openEditArtistSchedule(a) {
      this.editingArtistSchedule = a
      this.editingStartTime = a.start_time
      this.editingEndTime = a.end_time
      this.openEditingArtistSchedule = true
    },
    cancelEditingArtistSchedule() {
      this.editingArtistSchedule = null
      this.editingStartTime = null
      this.editingEndTime = null
      this.openEditingArtistSchedule = false
    },
    saveArtistSchedule() {
      this.editingArtistSchedule.start_time = this.editingStartTime
      this.editingArtistSchedule.end_time = this.editingEndTime
      updateArtistSchedule(this.eventId, this.editingArtistSchedule.id, {
        'start_time': this.editingStartTime,
        'end_time': this.editingEndTime
      }).then(() => {
        store.addAlert("success", "Artist schedule updated")
        this.cancelEditingArtistSchedule()
      })
    },
    deleteArtistSchedule(a) {
      if (window.confirm("Are you sure you want to delete this performance?")) {
        deleteArtistPerformance(this.eventId, this.editingArtistSchedule.id).then(() => {
          store.addAlert("success", "Artist performance removed")
          this.editingSchedule.artists = this.editingSchedule.artists.filter(artist => artist.id !== a.id)
        })
      }
    },
    addSchedule() {
      addEventSchedule(this.eventId, {
        'name': this.name,
        'scheduleDate': this.date,
        'endTime': this.endTime,
        'startTime': this.startTime
      }).then(result => {
        this.schedules.push(result)
        this.cancelAddingSchedule()
        store.addAlert("success", "Schedule added")
      })
    },
    cancelAddingSchedule() {
      this.addingSchedule = false
      this.name = ''
      this.date = null
      this.startTime = null
      this.endTime = null
    },
    cancelAddingArtist() {
      this.artistEndTime = null
      this.artistStartTime = null
      this.selectedStageId = null
      this.selectedArtistId = null
      this.selectedScheduleId = null
      this.addingArtist = false
    },
    editArtistSchedule(a) {
      this.editingArtistSchedule = a
    },
    addArtist(scheduleId) {
      this.addingArtist = true
      this.selectedScheduleId = scheduleId
    },
    saveArtist() {
      this.savingArtist = true
      saveArtistScheduleBooking(this.eventId, this.selectedScheduleId, {
        'stage_id': this.selectedStageId,
        'artist_id': this.selectedArtistId,
        'start_time': this.artistStartTime,
        'end_time': this.artistEndTime
      })
        .then(result => {
          const s = this.schedules.filter(s => s.id === this.selectedScheduleId)[0]
          s.artists.push(result)
          store.addAlert("success", "Artist added to schedule")
        })
        .finally(() => this.cancelAddingArtist())
    },
    getArtist(artistId) {
      return this.artists.filter(a => a.id === artistId)[0]
    },
    async saveStage() {
      const added = await addStage(this.eventId, {'name': this.stageName})
      this.stages.push(added)
      this.addingStage = false
    }
  },
  async mounted() {
    this.artists = await getEventArtists(this.eventId)
    this.event = await getEventData(this.eventId)
    this.schedules = await getEventSchedules(this.eventId)
    this.stages = await getStages(this.eventId)
  }
}
</script>
