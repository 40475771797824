<template>
  <div>
    <Navbar :event="event" :eventId="eventId" currentPage="People"/>
    <div id="" class="container-fluid">
      <div v-if="event" class="d-flex flex-row mt-3">
        <div class="flex-grow-1">
          <Breadcrumbs :event="event" currentPageName="People"/>
        </div>
      </div>
      <div v-if="event">
        <h2>People</h2>
        <button @click="openAddNewPerson" class="mt-5 mb-3 btn btn-primary me-2">Add new person</button>
        <button @click="openMultiAdd" class="mt-5 mb-3 btn btn-primary">Multi-add</button>
        <hr/>
        <h3 class="mt-5 mb-3">Units</h3>
        <div v-if="!isOnlyGuestlist" class="row mt-3">
          <div class="col-8">
            <div class="item-title">
              Add unit
            </div>
            <div class="row">
              <div class="col-auto">
                <input type="text" class="form-control pe-0" v-model="unitName"/>
              </div>
              <div class="col-auto p-0">
                <button class="btn btn-primary btn-small" @click="addUnit">Add unit</button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 ms-3">
          <div class="mt-2 list-group" v-for="unit in units" v-bind:key="unit.id">
            <div class="row" style="border: 1px solid; border-radius: 3px">
              <span style="cursor: pointer" @click="editUnit(unit.id)" class="col-9">{{ unit.name }}</span>
              <span class="col-3 mh-100 flex-shrink-1 d-flex justify-content-end align-items-center">
              <span v-if="!isOnlyGuestlist" @click="startAddGroup(unit.id)" class="ms-2 pointer">+</span>
              <span @click="editUnit(unit.id)"><Icon name="edit" class="m-2"/></span>
              <span v-if="!isOnlyGuestlist" @click="removeUnit(unit.id)"><Icon name="trash"/></span>
              <span v-if="!isOnlyGuestlist">
              <span v-if="selectedUnit === unit.id" @click="selectUnit(unit.id)"><Icon name="shrink"
                                                                                       class="m-2"/></span>
              <span v-else @click="selectUnit(unit.id)"><Icon v-on:click="selectUnit(unit.id)" name="expand"
                                                              class="m-2"/></span>
              </span>
            </span>
              <div v-if="!isOnlyGuestlist && selectedUnit === unit.id" class="row my-2">
                <div class="col-auto">
                  <select class="form-select" v-model="unit.profile_id">
                    <option disabled="disabled" :value="null">Select profile</option>
                    <option v-for="profile in profiles" :value="profile.id" v-bind:key="profile.id">{{ profile.name }}
                    </option>
                  </select>
                </div>
                <div class="col-auto">
                  <button @click="updateUnitProfile(unit)" class="btn btn-primary">Set unit profile</button>
                </div>
              </div>
            </div>
            <div class="mt-2 row" v-if="addingGroup && selectedUnit === unit.id">
              <div class="col-auto">
                <input placeholder="New group name" type="text" class="form-control" v-model="groupName"/>
              </div>
              <div class="col-auto">
                <button @click="addGroup(unit)" class="mx-2 btn btn-primary btn-small">Add group</button>
                <button @click="cancelAddGroup()" class="btn btn-primary btn-small">Cancel</button>
              </div>
            </div>
            <div class="ms-4" v-if="selectedUnit === unit.id && unit.groups.length > 0">
              <div class="subtitle2">Groups</div>
              <div class="row mb-2" style="border: 1px solid; border-radius: 3px" v-for="group in unit.groups"
                   v-bind:key="group.id">
                <span class="col-7">{{ group.name }}</span>
                <span class="col-5 mh-100 flex-shrink-1 d-flex justify-content-end align-items-center">
                <span @click="editGroup(group)"><Icon name="edit" class="m-2"/></span>
                <span @click="removeGroup(unit.id, group.id)"><Icon name="trash"/></span>
                <span v-if="selectedGroup === group.id" @click="selectGroup(group.id)"><Icon name="shrink" class="m-2"/></span>
                <span v-else @click="selectGroup(group.id)"><Icon name="expand" class="m-2"/></span>
              </span>
                <div v-if="selectedGroup === group.id" class="row my-2">
                  <div class="col-auto">
                    <select class="form-select" v-model="group.profile_id">
                      <option :value="null">Set profile</option>
                      <option v-for="profile in profiles" :value="profile.id" v-bind:key="profile.id">
                        {{ profile.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-auto">
                    <button @click="updateGroupProfile(group)" class="btn btn-primary">Set group profile</button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <hr class="mb-5"/>
        <PeopleData :people="people" :event="event" :selectedUnit="selectedUnit" :selectedGroup="selectedGroup"
                    :unit_names="unit_names" :group_names="group_names" :profiles="profiles" :units="units"
                    :unitId="null"
                    :onlyGuestlist="isOnlyGuestlist"
                    v-on:updatedProfile="updatedProfile"
                    v-on:updatedPerson="updatedPerson"/>
      </div>
    </div>
    <Modal v-bind:show-modal.sync="multiAddOpened" :modal-class="'modal-lg modal-full-screen modal-dialog-centered'">
      <div class="modal-content">
        <div class="modal-body">
          <div class="modal-content overflow-auto">
            <div class="modal-header">
              <h5 class="modal-title">Multi-add</h5>
              <div class="col-auto p-0">
              </div>
            </div>
            <div class="modal-body" v-if="multiAddStep === 1">
              <textarea class="form-control w-100" rows="10" v-model="multiAddPeopleAsText"></textarea>
              <p>Add each new entry onto a new row. The entry format is:<br/>Jane Jones +3, comment : Unit : Group</p>
            </div>
            <div class="modal-body" v-if="multiAddStep === 2">
              <div class="row">
                <div class="col">
                  Select unit:
                  <select class="form-select" v-model="multiAddUnitId" aria-label="Select unit">
                    <option v-for="unit in units" :value="unit.id" v-bind:key="'unit-'+unit.id">{{ unit.name }}</option>
                  </select>
                </div>
                <div class="col">
                  Select group:
                  <select class="form-select" v-model="multiAddGroupId" aria-label="Select unit">
                    <option :value="null">No group</option>
                    <option v-for="group in getUnitGroups(multiAddUnitId)" :value="group.id"
                            v-bind:key="'group-' + group.id">{{ group.name }}
                    </option>
                  </select>
                </div>
              </div>
              <table class="table">
                <thead>
                <tr>
                  <th>Name</th>
                  <th>Comments</th>
                  <th>Extra</th>
                  <th>Unit</th>
                  <th>Group</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="guest in multiAddGuestsList" v-bind:key="'guest-' + guest.id">
                  <td>
                    <div class="d-flex">
                      <input type="text" class="form-control" v-model="guest.name"/>
                      <span v-if="guest.exists" class="ms-2 text-danger">!!</span>
                    </div>
                  </td>
                  <td><input type="text" class="form-control" v-model="guest.comments"/></td>
                  <td><input type="text" class="form-control" style="width: 3em" v-model="guest.guests"/></td>
                  <td>
                    <select class="form-select" v-model="guest.unit_id" aria-label="Select unit">
                      <option :value="null">No unit</option>
                      <option v-for="unit in units" :value="unit.id" v-bind:key="unit.id">{{ unit.name }}</option>
                    </select>
                  </td>
                  <td>
                    <select class="form-select" v-model="guest.group_id" aria-label="Select unit">
                      <option :value="null">No group</option>
                      <option v-for="group in getUnitGroups(guest.unit_id)" :value="group.id"
                              v-bind:key="'group-'+group.id">{{ group.name }}
                      </option>
                    </select>
                  </td>
                  <td><input type="checkbox" v-model="guest.selected"/></td>
                </tr>
                </tbody>
              </table>
              <div class="d-flex justify-content-end">
                <button class="btn btn-primary" @click="removeSelectedPeople">Remove</button>
              </div>
            </div>
            <div class="modal-footer d-flex justify-content-center">
              <button type="submit" class="btn btn-primary"
                      data-dismiss="modal"
                      aria-label="Close"
                      @click="cancelMultiAdd()">
                Cancel
              </button>
              <button v-if="multiAddStep === 2" class="btn btn-primary me-2" @click="saveAllPeople">Save</button>
              <button v-if="multiAddStep === 1" type="button" class="btn btn-primary" @click="continueMultiAdd">Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <Modal v-bind:show-modal.sync="addingOnePerson" :modal-class="'modal-full-screen modal-dialog-centered'">
      <div class="modal-content">
        <div class="modal-body">
          <div class="modal-content overflow-auto">
            <div class="modal-header">
              <h5 class="modal-title">Add new person</h5>
            </div>
            <div class="modal-body">
              <div v-if="personExists" class="text-danger">
                Person with such a name already exists, do you still want to add them?
              </div>
              <div class="row mt-3">
                <div class="col-auto">
                  <div class="item-title mb-2">
                    Name
                  </div>
                  <div>
                    <div class="row">
                      <div class="col-auto">
                        <input type="text" class="form-control pe-0" v-model="personName"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3" v-if="!isOnlyGuestlist">
                <div class="col-auto">
                  <div class="item-title mb-2">
                    Unit
                  </div>
                  <div>
                    <div class="row">
                      <div class="col-auto">
                        <select required class="form-select" v-model="personUnitId" aria-label="Select unit">
                          <option :value="null">No unit</option>
                          <option v-for="unit in units" :value="unit.id" v-bind:key="'unit-'+unit.id">{{
                              unit.name
                            }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3" v-if="!isOnlyGuestlist">
                <div class="col-auto">
                  <div class="item-title mb-2">
                    Group
                  </div>
                  <div>
                    <div class="row">
                      <div class="col-auto">
                        <select required class="form-select" v-model="personGroupId" aria-label="Select unit">
                          <option :value="null">No group</option>
                          <option v-for="group in getUnitGroups(personUnitId)" :value="group.id"
                                  v-bind:key="'group-' + group.id">{{ group.name }}
                          </option>
                        </select>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-auto">
                  <div class="item-title mb-2">
                    Comments
                  </div>
                  <div>
                    <div class="row">
                      <div class="col-auto">
                        <input type="text" class="form-control pe-0" v-model="personComments"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-auto">
                  <div class="item-title mb-2">
                    Extra
                  </div>
                  <div>
                    <div class="row">
                      <div class="col-auto">
                        <input type="text" class="form-control pe-0" v-model="personExtra"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary"
                      data-dismiss="modal"
                      aria-label="Close"
                      @click="closePersonAdding">
                Cancel
              </button>
              <button v-if="!personExists" type="button" class="btn btn-primary" @click="checkIfExistsAndAdd">Add new
                person
              </button>
              <button v-if="personExists" type="button" class="btn btn-primary" @click="addPerson">Add person again
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import {
  addEventGroup,
  addEventUnit, addGroupPerson, addMultipleGuests, addUnitPerson,
  deleteGroup,
  deleteUnit, eventCheckIfGuestExists, eventCheckIfGuestsExist,
  getEvent,
  getEventPeople,
  updateGroup,
  updateUnit
} from "@/api";
import Breadcrumbs from "@/components/Breadcrumbs";
import Navbar from "@/components/Navbar";
import PeopleData from "@/components/PeopleData";
import Icon from "@/components/Icon";
import router from "@/router";
import store from "@/store";
import Modal from "@/components/Modal";

export default {
  name: "EventPeople",
  props: ['eventId', 'unitId', 'groupId'],
  components: {Breadcrumbs, Navbar, PeopleData, Icon, Modal},
  data() {
    return {
      unitName: '',
      people: [],
      units: [],
      profiles: [],
      group_names: [],
      unit_names: [],
      groupName: '',
      addingGroup: false,
      selectedGroup: null,
      selectedUnit: null,
      event: null,
      isOnlyGuestlist: false,

      personExists: false,
      addingOnePerson: false,
      personName: '',
      personComments: '',
      personExtra: 0,
      personUnitId: '',
      personGroupId: '',

      multiAddPeopleAsText: '',
      multiAddOpened: false,
      newPersonAddOpened: false,
      multiAddStep: null,
      multiAddGuestsList: [],
      multiAddUnitId: null,
      multiAddGroupId: null,
    }
  },
  async mounted() {
    const people = await getEventPeople(this.eventId)
    this.people = people.people
    this.fields = people.fields

    this.checkedPeople = []

    const result = await getEvent(this.eventId)
    this.event = result.event
    this.group_names = result.group_names
    this.units = result.units
    this.unit_names = result.unit_names
    this.wristbands = result.wristbands
    this.badges = result.badges
    this.profiles = result.profiles
    this.isOnlyGuestlist = store.isOnlyGuestlist()
  },
  methods: {
    openMultiAdd() {
      this.multiAddOpened = true
      this.multiAddStep = 1
    },
    openAddNewPerson() {
      this.addingOnePerson = true
    },
    cancelAddNewPerson() {
      this.addingOnePerson = false
      this.personComments = ''
      this.personName = ''
      this.personExtra = 0
      this.personGroupId = null
      this.personUnitId = null
      this.personExists = false
    },
    async addPerson() {
      if (this.personGroupId) {
        addGroupPerson(this.eventId, this.personUnitId, this.personGroupId, {
          name: this.personName,
          comments: this.personComments,
          extra: this.personExtra
        }).then(result => {
          this.people.push(result)
          this.cancelAddNewPerson()
        })
      } else {
        if (this.isOnlyGuestlist) {
          addUnitPerson(this.eventId, this.units[0].id, {
            name: this.personName,
            comments: this.personComments,
            extra: this.personExtra
          }).then(result => {
            this.people.push(result)
            this.cancelAddNewPerson()
          })
        } else {
          addUnitPerson(this.eventId, this.personUnitId, {
            name: this.personName,
            comments: this.personComments,
            extra: this.personExtra
          }).then(result => {
            this.people.push(result)
            this.cancelAddNewPerson()
          })
        }
      }
    },
    async checkIfExistsAndAdd() {
      const exists = await eventCheckIfGuestExists(this.eventId, this.personName)
      if (exists.exists) {
        this.personExists = true
      } else {
        this.addPerson()
      }
    },
    closePersonAdding() {
      this.cancelAddNewPerson()
    },


    cancelMultiAdd() {
      this.multiAddOpened = false
      this.multiAddPeopleAsText = ''
      this.multiAddGuestsList = []
    },
    continueMultiAdd() {
      eventCheckIfGuestsExist(this.eventId, this.multiAddPeopleAsText).then((result) => {
        this.multiAddStep = 2
        this.multiAddGuestsList = result
      })
    },
    async saveAllPeople() {
      this.multiAddGuestsList.forEach(g => {
        if (g.unit_id === null) {
          g.unit_id = this.multiAddUnitId
          g.group_id = this.multiAddGroupId
        }
      })

      let result = await addMultipleGuests(this.eventId, this.multiAddGuestsList, null, null)
      this.people.push(...result)
      this.cancelMultiAdd()
    },
    removeSelectedPeople() {
      this.multiAddGuestsList = this.multiAddGuestsList.filter(g => g.selected !== true)
    },

    getUnitGroups(unit_id) {
      let unit = this.units.find(u => u.id === unit_id)
      if (unit) {
        return unit.groups
      }
    },


    async addUnit() {
      const unit = await addEventUnit(this.eventId, {name: this.unitName})
      this.units.push(unit)
      this.unitName = ''
    },
    selectGroup(group) {
      if (this.selectedGroup === group) {
        this.selectedGroup = null
      } else {
        this.selectedGroup = group
      }
      this.$emit('selectedGroup', group)
    },
    editUnit(unitId) {
      router.push({name: 'EventUnit', params: {eventId: this.event.id, unitId: unitId}})
    },
    editGroup(group) {
      router.push({name: 'EventGroup', params: {eventId: this.event.id, unitId: group.unitId, groupId: group.id}})
    },
    removeGroup(unitId, groupId) {
      if (confirm("Are you sure you want to remove this group?")) {
        deleteGroup(this.eventId, unitId, groupId)
        let unit = this.units.filter(u => u.id === unitId)
        if (unit.length === 1) {
          unit[0].groups = unit[0].groups.filter(g => g.id !== groupId)
        }
      }
    },
    removeUnit(unitId) {
      if (confirm("Are you sure you want to remove this unit?")) {
        deleteUnit(this.eventId, unitId)
        this.units = this.units.filter(u => u.id !== unitId)
      }
    },
    updatedProfile(profileId, updatedPeople) {
      this.people.forEach(p => {
        if (updatedPeople.includes(p.id)) {
          p.profile_id = profileId
        }
      })
    },
    updatedPerson(personId, data) {
      let person = this.people.find(p => p.id === personId)
      person.name = data.name
      person.comments = data.comments
      person.guest_count = data.guest_count
    },
    selectUnit(unit) {
      if (this.selectedUnit === unit) {
        this.selectedUnit = null
      } else {
        this.selectedUnit = unit
        this.selectedGroup = null
      }
      this.$emit('selectedUnit', unit)
    },
    startAddGroup(unitId) {
      this.selectedUnit = unitId
      this.addingGroup = true
    },
    async addGroup(unit) {
      const group = await addEventGroup(this.eventId, unit.id, {name: this.groupName})
      unit.groups.push(group)
      this.groupName = ''
      this.addingGroup = false
    },
    cancelAddGroup() {
      this.addingGroup = false
    },
    updateUnitProfile(unit) {
      updateUnit(this.event.id, unit.id, {profileId: unit.profile_id})
    },
    updateGroupProfile(group) {
      updateGroup(this.event.id, group.unitId, group.id, {profile_id: group.profile_id})
    }
  },
}
</script>
