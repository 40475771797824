<template>
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100 text-center">
      <div class="col-12">
        <h1>Select artist</h1>
        <button v-for="artist in artists" class="btn btn-primary me-3" @click="setArtist(artist.id)"
                v-bind:key="artist.id">{{artist.name}}</button>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";

export default {
  name: 'ArtistSelectArtist',
  components: {},
  data() {
    return {
      artists: []
    }
  },
  async mounted() {
    this.artists = store.getArtists()
  },
  methods: {
    async setArtist(id) {
      store.setArtist(id)
      this.$router.replace({name: 'ArtistAdvancingForm'})
    }
  }
}
</script>
