<template>
  <div class="container">
    <template v-if="accessSystem !== null">
      <AccessSystemDisplay :accessSystem="accessSystem" :event="event" :editing="false" />
    </template>
  </div>
</template>
<script>

import AccessSystemDisplay from "@/components/AccessSystemDisplay";
import {getAccessSystem} from "@/api";

export default {
  name: 'AccessSystemView',
  props: ['eventId'],
  components: {
    AccessSystemDisplay,
  },
  data() {
    return {
      event: null,
      accessSystem: null,
    }
  },
  async mounted() {
    this.accessSystem = await getAccessSystem(this.eventId)
    this.event = this.accessSystem.event
  }
}
</script>
