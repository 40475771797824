<template>
  <div class="container-fluid ps-1">
    <div class="row flex-nowrap">
      <div class="col-auto col-md-3 col-xl-2 px-sm-2 px-0">
        <div class="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 min-vh-100">
          <a href="/" class="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-decoration-none">
            <h5 class="fs-5 d-none d-sm-inline text-uppercase">{{event.name}}</h5>
          </a>
          <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
            <li :class="[currentPage === 'Form' ? 'menu-active': 'menu-itme']">
              <router-link :to="{name: 'ArtistAdvancingForm' }" class="nav-link">
                Advancing form
              </router-link>
            </li>
            <li :class="[currentPage === 'Schedule' ? 'menu-active': 'menu-itme']">
              <router-link :to="{name: 'ArtistPageTimetable' }" class="nav-link">
                Schedule
              </router-link>
            </li>
            <li :class="[currentPage === 'GeneralInformation' ? 'menu-active': 'menu-itme']">
              <router-link :to="{name: 'ArtistPageGeneralInformation' }" class="nav-link">
                General information
              </router-link>
            </li>
            <li :class="[currentPage === 'Accomodation' ? 'menu-active': 'menu-itme']">
              <router-link :to="{name: 'ArtistPageAccomodation' }" class="nav-link">
                Accomodation
              </router-link>
            </li>
            <li :class="[currentPage === 'MyAccount' ? 'menu-active': 'menu-itme']">
              <router-link :to="{name: 'ArtistMyAccount' }" class="nav-link">
                My account
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="col">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ArtistPageMenu",
  props: ['event', 'currentPage'],

}
</script>
