<template>
  <div>
    <div class="row mt-5">
      <div class="col-md-3">
        <input class="form-control" type="text" placeholder="Search logs" v-model="logSearch"/>
      </div>
    </div>
    <table class="table">
      <thead>
      <tr>
        <th>Time</th>
        <th>User</th>
        <th>Details</th>
      </tr>
      </thead>
      <tr v-for="log in filteredLogs" v-bind:key="log.id">
        <td class="p-1">{{ log.timestamp | formatDate }}</td>
        <td class="p-1">{{ log.user }}</td>
        <td class="p-1">{{ log.details }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: "ActivityLogs",
  props: ['logs'],
  data() {
    return {
      logSearch: ''
    }
  },
  methods: {
    filterLogs(log) {
      if (log.details && (log.details.toLowerCase().includes(this.logSearch.toLowerCase()) || log.user.toLowerCase().includes(this.logSearch.toLowerCase()))) {
        return true
      }
    }
  },
  computed: {
    filteredLogs: function () {
      return this.logs.filter(log => {
        return this.filterLogs(log)
      })
    }
  }
}
</script>
