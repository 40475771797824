<template>
  <div id="" class="container-fluid">
    <div class="row flex-nowrap">
      <div class="col-auto col-md-3 col-xl-2 px-sm-2 px-0">
        <div class="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
          <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">

            <li>
              <router-link :to="{name: 'AccountUsers'}" class="nav-link px-0 align-middle">
                <span class="ms-1 d-none d-sm-inline">Users</span></router-link>
            </li>
            <li>
              <router-link :to="{name: 'AccountSettings'}" class="nav-link px-0 align-middle">
                <span class="ms-1 d-none d-sm-inline">Settings</span></router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="col py-3">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "AccountAdminMenu",
  components: {  },
};
</script>
