<template>
  <div>
    <Navbar :event="event" :eventId="eventId" currentPage="Artists"/>
    <div class="container-fluid">

      <h1>Change artist data</h1>
      <div class="card elevation-16 mb-3" v-if="artist">
        <div class="card-body">
          <form v-on:submit.prevent="addArtist">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <div class="item-title mb-2">
                    Full name
                  </div>
                  <div class="row">
                    <div class="col-auto">
                      <input required type="text" class="mb-2 form-control pe-0" v-model="artist.name"/>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="item-title mb-2">
                    Contacts deadline
                  </div>
                  <div class="row">
                    <div class="col-auto">
                      <input required type="date" class="mb-2 form-control pe-0" v-model="artist.contact_deadline"/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="item-title mb-2">
                    Performers limit
                  </div>
                  <div class="row">
                    <div class="col-auto">
                      <input required type="number" class="mb-2 form-control pe-0" v-model="artist.performers_limit"/>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="item-title mb-2">
                    Performers deadline
                  </div>
                  <div class="row">
                    <div class="col-auto">
                      <input required type="date" class="mb-2 form-control pe-0" v-model="artist.performers_deadline"/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="item-title mb-2">
                    Crew limit
                  </div>
                  <div class="row">
                    <div class="col-auto">
                      <input required type="number" class="mb-2 form-control pe-0" v-model="artist.crew_limit"/>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="item-title mb-2">
                    Crew deadline
                  </div>
                  <div class="row">
                    <div class="col-auto">
                      <input required type="date" class="mb-2 form-control pe-0" v-model="artist.crew_deadline"/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="item-title mb-2">
                    Backstage guestlist limit
                  </div>
                  <div class="row">
                    <div class="col-auto">
                      <input required type="number" class="mb-2 form-control pe-0" v-model="artist.backstage_guestlist_limit"/>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="item-title mb-2">
                    Backstage guest list deadline
                  </div>
                  <div class="row">
                    <div class="col-auto">
                      <input required type="date" class="mb-2 form-control pe-0" v-model="artist.backstage_guestlist_deadline"/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="item-title mb-2">
                    General admission guest list limit
                  </div>
                  <div class="row">
                    <div class="col-auto">
                      <input required type="number" class="mb-2 form-control pe-0" v-model="artist.ga_guestlist_limit"/>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="item-title mb-2">
                    General admission guest list deadline
                  </div>
                  <div class="row">
                    <div class="col-auto">
                      <input required type="date" class="mb-2 form-control pe-0" v-model="artist.ga_guestlist_deadline"/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="item-title mb-2">
                    Technical & hospitality rider deadline
                  </div>
                  <div class="row">
                    <div class="col-auto">
                      <input required type="date" class="mb-2 form-control pe-0" v-model="artist.rider_deadline"/>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="item-title mb-2">
                    Other files (show visuals, etc) deadline
                  </div>
                  <div class="row">
                    <div class="col-auto">
                      <input required type="date" class="mb-2 form-control pe-0" v-model="artist.other_files_deadline"/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="item-title mb-2">
                    Performance time
                  </div>
                  <div class="row">
                    <div class="col-auto">
                      From <input required type="datetime-local" class="mb-2 form-control pe-0" v-model="start_time"/>
                      until <input required type="datetime-local" class="mb-2 form-control pe-0" v-model="end_time"/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <input type="submit" class="btn btn-primary" value="Save"/>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import Navbar from '@/components/Navbar.vue'
import store from "@/store";
import {addArtist, getEventArtis, getStages, updateArtist} from "@/artists_api";
import {getEvent} from "@/api";

export default {
  name: 'AddArtist',
  components: {Navbar},
  props: ['eventId', 'artistId'],
  data() {
    return {
      name: '',
      artist: null,
      event: null,
      start_time: null,
      end_time: null,
      stages: []
    }
  },
  async mounted() {
    this.artist = await getEventArtis(this.eventId, this.artistId)
    this.event = await getEvent(this.eventId)
    this.stages = await getStages(this.eventId)
  },
  methods: {
    async updateArtist() {
      await updateArtist(this.eventId, this.artist)
      this.$router.replace({name: 'Artists'})
    }
  }
}
</script>
