import store from "@/store";
import router from "@/router";

export function get(path) {
  return callApi('GET', path, null)
}

export function post(path, data) {
  return callApi('POST', path, JSON.stringify(data), null)
}

export function postWithTimeout(path, data) {
  return callApi('POST', path, JSON.stringify(data), TIMEOUT)
}

function handleResponse(response) {

}

export class HttpError extends Error {

  constructor(path, requestBody, responseCode, responseBody) {
    super(JSON.stringify({path, requestBody, responseCode, responseBody}))

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, HttpError)
    }
    this.path = path
    this.name = 'HttpError'
    this.requestBody = requestBody
    this.responseCode = responseCode
    this.responseBody = responseBody
  }

}


async function fetchWithTimeout2(resource, options = {}) {
  const { timeout = 8000 } = options;

  if (timeout) {
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    const response = await fetch(resource, {
      ...options,
      signal: controller.signal
    });

    clearTimeout(id);
    return response;
  } else {
    const response = await fetch(resource, options)
    return response
  }
}

const TIMEOUT = 5000 // Fetchi timeouti pikkus, 5 sekundit

async function callApi(method, path, body, timeout) {
  const headers = new Headers({})
  headers.set('Authorization', "Bearer " + store.jwt())
  if (method === 'POST') {
    headers.set('Content-Type', 'application/json')
  }

  //const response = fetchWithTimeout(path, {method, headers, body: body, timeout: timeout})
  const response = await fetchWithTimeout2(path, {method, headers, body: body, timeout: timeout})
  //const response = await fetch(path,{method, headers, body: body})
  if (response.status === 401 || response.status === 422) {
    router.push('/login')
  }

  if (!response.ok) {
    const error = {status: response.status, errors: {}}
    const json = response.status === 204 ? {} : await response.json()
    return Promise.reject({...error, ...json})
  }

  return await response.json()
}
