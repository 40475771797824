<template>
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-12 col-md-8 col-lg-6 col-xl-5">
        <div class="card shadow-2-strong" style="border-radius: 1rem;">
          <div class="card-body p-5 text-center">
            <form v-on:submit.prevent="register">
              <h3 class="mb-5">Register</h3>
              <div class="form-outline mb-2">
                <label class="form-label mt-1" for="name">Full name</label>
                <input type="text" id="name" autocomplete="off" v-model="userName" required="required"
                       class="form-control form-control-lg"/>
              </div>
              <div class="form-outline mb-2">
                <label class="form-label mt-1" for="accountName">Company name</label>
                <input type="text" id="accountName" autocomplete="off" v-model="accountName"
                       class="form-control form-control-lg"/>
              </div>
              <div class="form-outline mb-2">
                <label class="form-label mt-1" for="email">Your e-mail address</label>
                <input type="email" id="email" v-model="email" required="required"
                       class="form-control form-control-lg"/>

                <span class="errors d-block" v-if="errors.e_mail">{{ errors.e_mail }}</span>
              </div>
              <div class="form-outline mb-2">
                <label class="form-label mt-1" for="password">Password</label>
                <input type="password" id="password" v-model="password" required="required"
                       class="form-control form-control-lg"/>
                <span class="errors d-block" v-if="errors.password">{{ errors.password }}</span>
              </div>
              <div class="form-outline mb-2">
                <label class="form-label mt-1" for="password2">Re-enter password</label>
                <input type="password" id="password2" v-model="password_again" required="required"
                       class="form-control form-control-lg"/>
              </div>
              <button class="btn btn-primary btn-block" type="submit">Register</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import store from "@/store";

export default {
  name: 'Register',
  components: {},
  data() {
    return {
      email: '',
      password: '',
      password_again: '',
      userName: '',
      accountName: '',
      errors: {}
    }
  },
  methods: {
    async login() {
      await axios.post('/api/auth', {username: this.email, password: this.password})
        .then(result => {
          store.saveJwt(result.data.access_token)
          this.$router.replace({name: 'Events'}).catch(() => {
          });
        })
    },
    async register() {
      await axios.post('/api/register', {
        email: this.email, password: this.password, userName: this.userName, password2: this.password_again,
        accountName: this.accountName
      }).then(result => {
        if (result.data.errors) {
          this.errors = result.data.errors
        } else {
          this.login()
        }
      })
    }
  }
}
</script>
