import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Home from '../views/Home.vue'
import Register from '../views/Register.vue'
import Login from '../views/Login.vue'
import Events from '../views/Events.vue'
import AddEvent from '../views/AddEvent.vue'
import Event from '../views/Event.vue'
import AccessSystem from '../views/AccessSystem.vue'
import EventUnits from '../views/EventUnits.vue'
import EventUnit from '../views/EventUnit.vue'
import EventPeople from '../views/EventPeople.vue'
import EventGroup from '../views/EventGroup.vue'
import UserHome from '../views/UserHome.vue'
import UserDashboard from "@/views/UserDashboard";
import CheckIn from "@/views/CheckIn";
import EventUsers from "@/views/EventUsers";
import EventAllData from "@/views/EventAllData";
import MenuTest from "@/views/MenuTest";
import AccessSystemView from "@/views/AccessSystemView";
import AllEvents from "@/views/AllEvents";
import MyAccount from "@/views/MyAccount";
import AccountAdmin from "@/views/AccountUsers";
import AccountPaymentPlan from "@/views/AccountPaymentPlan";
import AccountPaymentPlanSelect from "@/views/AccountPaymentPlanSelect";
import AccountSettings from "@/views/AccountSettings";
import AccountExtraFeatures from "@/views/AccountExtraFeatures";
import AccountUsers from "@/views/AccountUsers";
import Artists from "@/views/Artists";
import AddArtist from "@/views/artist/AddArtist";
import Artist from "@/views/Artist";
import Stages from "@/views/Stages";
import EditArtist from "@/views/EditArtist";
import Timetable from "@/views/artists/Schedules";
import ArtistAdvancingForm from "@/views/artist/ArtistAdvancingForm";
import AdvancingForms from "@/views/AdvancingForms";
import ArtistsFiles from "@/views/ArtistsFiles";
import ArtistsAccomodation from "@/views/artists/ArtistsAccomodation";
import ArtistsGeneralInformation from "@/views/artists/ArtistsGeneralInformation";
import AdvancingForm from "@/views/AdvancingForm";
import AdvancingFormArtist from "@/views/AdvancingFormArtist";
import ArtistPageTimetable from "@/views/artist/ArtistPageTimetable";
import ArtistPageAccomodation from "@/views/artist/ArtistPageAccomodation";
import ArtistPageGeneralInformation from "@/views/artist/ArtistPageGeneralInformation";
import SetArtist from "@/views/SetPassword";
import ArtistSelectArtist from "@/views/artist/ArtistSelectArtist";
import ArtistMyAccount from "@/views/artist/ArtistMyAccount";
import AddHotel from "@/views/artists/AddHotel";
import ArtistsActivityLog from "@/views/artists/ArtistsActivityLog";


Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Home', component: Events, meta: {requiresRole: 'employee'} },
  { path: '/account-users', name: 'AccountUsers', component: AccountUsers, meta: {requiresRole: 'employee'} },
  { path: '/account-payment-plan-select', name: 'AccountPaymentPlanSelect', component: AccountPaymentPlanSelect, meta: {requiresRole: 'employee'} },
  { path: '/account-payment-plan', name: 'AccountPaymentPlan', component: AccountPaymentPlan, meta: {requiresRole: 'employee'} },
  { path: '/account-settings', name: 'AccountSettings', component: AccountSettings, meta: {requiresRole: 'employee'} },
  { path: '/account-extra-features', name: 'AccountExtraFeatures', component: AccountExtraFeatures, meta: {requiresRole: 'employee'} },
  { path: '/register', name: 'Register', component: Register },
  { path: '/login', name: 'Login', component: Login },
  { path: '/events', name: 'Events', component: Events, meta: {requiresRole: 'employee'} },
  { path: '/all-events', name: 'AllEvents', component: AllEvents, meta: {requiresRole: 'employee'} },
  { path: '/user-home', name: 'UserHome', component: UserHome, meta: {requiresRole: 'employee'} },
  { path: '/add-event', name: 'AddEvent', component: AddEvent, meta: {requiresRole: 'employee'} },
  { path: '/event/:id', name: 'Event', component: Event, props: true, meta: {requiresRole: 'employee'} },
  { path: '/event/:eventId/access', name: 'AccessSystem', component: AccessSystem, props: true, meta: {requiresRole: 'employee'} },
  { path: '/event/:eventId/view_access', name: 'AccessSystemView', component: AccessSystemView, props: true, meta: {requiresRole: 'employee'} },
  { path: '/event/:eventId/all_data', name: 'EventAllData', component: EventAllData, props: true, meta: {requiresRole: 'employee'} },
  { path: '/event/:eventId/check_in', name: 'CheckIn', component: CheckIn, props: true, meta: {requiresRole: 'employee', permission: 'check-in'} },
  { path: '/event/:eventId/users', name: 'EventUsers', component: EventUsers, props: true, meta: {requiresRole: 'employee'} },
  { path: '/event/:eventId/artists', name: 'Artists', component: Artists, props: true, meta: {requiresRole: 'employee'} },
  { path: '/event/:eventId/files', name: 'ArtistsFiles', component: ArtistsFiles, props: true, meta: {requiresRole: 'employee'} },
  { path: '/event/:eventId/general-information', name: 'ArtistsGeneralInformation', component: ArtistsGeneralInformation, props: true, meta: {requiresRole: 'employee'} },
  { path: '/event/:eventId/accomodation', name: 'ArtistsAccomodation', component: ArtistsAccomodation, props: true, meta: {requiresRole: 'employee'} },
  { path: '/event/:eventId/timetable', name: 'Timetable', component: Timetable, props: true, meta: {requiresRole: 'employee'} },
  { path: '/event/:eventId/artists-activity-log', name: 'ArtistsActivityLog', component: ArtistsActivityLog, props: true, meta: {requiresRole: 'employee'} },
  { path: '/event/:eventId/artists/add', name: 'AddArtist', component: AddArtist, props: true, meta: {requiresRole: 'employee'} },
  { path: '/artist-page', name: 'ArtistAdvancingForm', component: ArtistAdvancingForm, props: true, meta: {requiresRole: 'artist'} },
  { path: '/artist-timetable', name: 'ArtistPageTimetable', component: ArtistPageTimetable, props: true, meta: {requiresRole: 'artist'} },
  { path: '/artist-general-information', name: 'ArtistPageGeneralInformation', component: ArtistPageGeneralInformation, props: true, meta: {requiresRole: 'artist'} },
  { path: '/artist-accomodation', name: 'ArtistPageAccomodation', component: ArtistPageAccomodation, props: true, meta: {requiresRole: 'artist'} },
  { path: '/artist-account', name: 'ArtistMyAccount', component: ArtistMyAccount, props: true},
  { path: '/event/:eventId/artists/:artistId', name: 'Artist', component: Artist, props: true, meta: {requiresRole: 'employee'} },
  { path: '/event/:eventId/artists/:artistId/edit', name: 'EditArtist', component: EditArtist, props: true, meta: {requiresRole: 'employee'} },
  { path: '/event/:eventId/units', name: 'EventUnits', component: EventUnits, props: true, meta: {requiresRole: 'employee', permission: 'people'} },
  { path: '/event/:eventId/stages', name: 'Stages', component: Stages, props: true, meta: {requiresRole: 'employee'} },
  { path: '/event/:eventId/advancing-forms', name: 'AdvancingForms', component: AdvancingForms, props: true, meta: {requiresRole: 'employee'} },
  { path: '/event/:eventId/advancing-form/:formId', name: 'AdvancingForm', component: AdvancingForm, props: true, meta: {requiresRole: 'employee'} },
  { path: '/event/:eventId/advancing-form-artist/:artistId', name: 'AdvancingFormArtist', component: AdvancingFormArtist, props: true, meta: {requiresRole: 'employee'} },
  { path: '/event/:eventId/people', name: 'EventPeople', component: EventPeople, props: true, meta: {requiresRole: 'employee', permission: 'people'} },
  { path: '/event/:eventId/unit/:unitId', name: 'EventUnit', component: EventUnit, props: true, meta: {requiresRole: 'employee', permission: 'people'} },
  { path: '/event/:eventId/unit/:unitId/group/:groupId', name: 'EventGroup', component: EventGroup, props: true, meta: {requiresRole: 'employee', permission: 'people'} },
  { path: '/event/:eventId/hotels/add', name: 'AddHotel', component: AddHotel, props: true, meta: {requiresRole: 'employee'} },
  { path: '/set-password', name: 'SetUserPassword', component: SetArtist, props: true },
  { path: '/select-artist', name: 'ArtistSelectArtist', component: ArtistSelectArtist, props: true },
  { path: '/my-account', name: 'MyAccount', component: MyAccount, props: true},
  { path: '/event/:eventId/menutest', name: 'MenuTest', component: MenuTest, props: true}
]

export const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || "Mistin"
  });
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login', '/register', '/', '/register', '/set-password'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = store.getUser();

  if (authRequired && !loggedIn) {
    return next('/login');
  }

  let user = store.getUser()
  let artists = store.getArtists()

  if (to.meta && to.meta.requiresRole === 'employee') {
    if (user && artists !== null && artists.length > 0) {
      next({
        path: '/artist-page',
        query: {redirect: to.fullPath}
      })
    } else {
      if (authRequired) {
        const eventusers = store.getEventUsers()
        if (eventusers && eventusers.length === 1) {
          if (eventusers[0].role === 'security') {
            if (to.name !== 'AccessSystemView') {
              return {name: 'Login'}
            }
          }
          if (eventusers[0].role === 'worker_bee' && !(to.name === "CheckIn" || to.name === "Events" )) {
            next({
              path: `/event/${eventusers[0].event_id}/check_in`
            })
          } else if (eventusers[0].role === 'worker_bee+' && !(to.name === "CheckIn" || to.name === "Events"
            || to.name === 'EventPeople' || to.name === 'EventUnit' || to.name === 'EventGroup' )) {
            next({
              path: `/event/${eventusers[0].event_id}/check_in`
            })
          }
        }
      }
    }
  }

  next();
})

export default router
