<template>
  <div class="container-fluid ps-1">
    <div class="row flex-nowrap">
      <div class="col-auto col-md-3 col-xl-2 px-sm-2 px-0">
        <div class="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 min-vh-100">
          <a href="/" class="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-decoration-none">
            <h5 class="fs-5 d-none d-sm-inline text-uppercase">{{event.name}}</h5>
          </a>
          <ul class="nav flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
            <li :class="[active === 'artist' ? 'fw-bold': '']">
              <router-link :to="{name: 'Artists', params: { eventId: eventId }}"
                           href="#" class="nav-link px-0 align-middle ms-1">
                Artists
              </router-link>
            </li>
            <li :class="[active === 'form' ? 'fw-bold': '']">
              <router-link :to="{name: 'AdvancingForms', params: { eventId: eventId }}"
                           href="#" class="nav-link px-0 align-middle ms-1">
                Advancing form
              </router-link>
            </li>
            <li :class="[active === 'schedule' ? 'fw-bold': '']">
              <router-link :to="{name: 'Timetable', params: { eventId: eventId }}"
                           href="#" class="nav-link px-0 align-middle ms-1">
                Schedule
              </router-link>
            </li>
            <li :class="[active === 'general-information' ? 'fw-bold': '']">
              <router-link :to="{name: 'ArtistsGeneralInformation', params: { eventId: eventId }}"
                           href="#" class="nav-link px-0 align-middle ms-1">
                General information
              </router-link>
            </li>
            <li :class="[active === 'accomodation' ? 'fw-bold': '']">
              <router-link :to="{name: 'ArtistsAccomodation', params: { eventId: eventId }}"
                           href="#" class="nav-link px-0 align-middle ms-1">
                Accomodation
              </router-link>
            </li>
            <li :class="[active === 'files' ? 'fw-bold': '']">
              <router-link :to="{name: 'ArtistsFiles', params: { eventId: eventId }}"
                           href="#" class="nav-link px-0 align-middle ms-1">
                Files
              </router-link>
            </li>
            <li :class="[active === 'activity_log' ? 'fw-bold': '']">
              <router-link :to="{name: 'ArtistsActivityLog', params: { eventId: eventId }}"
                           href="#" class="nav-link px-0 align-middle ms-1">
                Activity log
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="col">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ArtistsMenu.vue",
  props: ['eventId', 'active', 'event'],
}
</script>
