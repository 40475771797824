<template>
  <button :disabled="loading" class="btn btn-primary me-2"  @click="handleClick">
    <div v-if="loading" class="spinner-border text-success spinner-border-sm" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div v-else>
      <slot></slot>
    </div>
  </button>
</template>

<script>
export default {
  name: "Button",
  props: ['loading'],
  methods: {
    handleClick() {
      this.$emit('click');
    }
  },
}
</script>
