<template>
  <div>
    <Modal v-bind:show-modal.sync="selectFreePlanModal" :modal-class="'modal-full-screen modal-dialog-centered'">
      <div class="modal-content">
        <div class="modal-body">
          <div class="modal-content overflow-auto">
            <div class="modal-header">
              <h5 class="modal-title">Free plan</h5>
              <div class="col-auto p-0">

              </div>
            </div>
            <div class="modal-body">
              <div class="row mt-3">
                <span class="pb-3">You have chosen the free plan.
                  You can upgrade at any time under the Payment Plan section in the Admin Panel.</span>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary"
              data-dismiss="modal"
              aria-label="Close"
              @click="closeSelectFreePlan()">
              Close
              </button>
              <button type="button" class="btn btn-primary" @click="selectFreePaymentPlan">Confirm</button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <Modal v-bind:show-modal.sync="selectPaymentPlanModal" :modal-class="'modal-full-screen modal-dialog-centered'">
      <div class="modal-content">
        <div class="modal-body">
          <div class="modal-content overflow-auto">
            <div class="modal-header">
              <h5 class="modal-title">Name of the plan</h5>
              <div class="col-auto p-0">

              </div>
            </div>
            <div class="modal-body">
              <div class="row mt-3">
                <span class="pb-3">You have chosen blabla plan. Please confirm to proceed to the payment</span>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary"
                      data-dismiss="modal"
                      aria-label="Close"
                      @click="closeSelectPlan()">
                Close
              </button>
              <button type="button" class="btn btn-primary" @click="selectPaymentPlan">Confirm</button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <Modal v-bind:show-modal.sync="selectCustomPlanModal" :modal-class="'modal-full-screen modal-dialog-centered'">
      <div class="modal-content">
        <div class="modal-body">
          <div class="modal-content overflow-auto">
            <div class="modal-header">
              <h5 class="modal-title">Custom plan</h5>
              <div class="col-auto p-0">

              </div>
            </div>
            <div class="modal-body">
              <div class="row mt-3">
                <span class="pb-3 errors" v-if="errorMessage">{{ errorMessage }}</span>
                <div class="col-auto">
                  <div class="item-title mb-2">
                    Name
                  </div>
                  <div>
                    <div class="row">
                      <div class="col-auto">
                        <input type="text" required="required" class="form-control pe-0" v-model="name"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-auto">
                  <div class="item-title mb-2">
                    Company name
                  </div>
                  <div>
                    <div class="row">
                      <div class="col-auto">
                        <input type="text" class="form-control pe-0" v-model="companyName"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-auto">
                  <div class="item-title mb-2">
                    Email
                  </div>
                  <div>
                    <div class="row">
                      <div class="col-auto">
                        <input type="text" class="form-control pe-0" required="required" v-model="email"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-auto">
                  <div class="item-title mb-2">
                    Approx. number of staff, partners, media, artists, etc. you wish to accredit
                  </div>
                  <div>
                    <div class="row">
                      <div class="col-auto">
                        <input type="text" class="form-control pe-0" required="required" v-model="numberOfGuests"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary"
                      data-dismiss="modal"
                      aria-label="Close"
                      @click="selectCustomPlanModal = false">
                Close
              </button>
              <button type="button" class="btn btn-primary" @click="selectPaymentPlan">Confirm</button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <Navbar/>
    <div id="" class="container-fluid">
      <div>
        <h1>Account admin</h1>
      </div>
      <div class="row">
        Payment plans
        <div v-for="plan in plans" v-bind:key="plan.id">
          <span>{{ plan.name }}</span>
        </div>

        <table class="table">
          <thead>
          <tr>
            <th></th>
            <th>Free</th>
            <th>One event</th>
            <th>Montly 1</th>
            <th>Monthly 2</th>
            <th>10 events</th>
            <th>Big Event or Custom Plan</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td></td>
            <td>0€</td>
            <td>30€</td>
            <td>100€</td>
            <td>100€</td>
            <td>200€</td>
            <td>custom</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <button class="btn btn-primary" @click="openSelectFreePlan()">Select</button>
            </td>
            <td>
              <button class="btn btn-primary" @click="openSelectPaymentPlan('one')">Select</button>
            </td>
            <td>
              <button class="btn btn-primary" @click="openSelectPaymentPlan('monthly1')">Select</button>
            </td>
            <td>
              <button class="btn btn-primary" @click="openSelectPaymentPlan('monthly2')">Select</button>
            </td>
            <td>
              <button class="btn btn-primary" @click="openSelectPaymentPlan('10events')">Select</button>
            </td>
            <td>
              <button class="btn btn-primary" @click="openSelectPlanCustomPlan()">Contact us</button>
            </td>
          </tr>
          <tr>
            <td>Specifics</td>
            <td>A basic list-only version</td>
            <td>For those who only need one event</td>
            <td>For those with a few events every month with more guests</td>
            <td>For those with many events every month with fewer guests</td>
            <td>For those who need more events over a longer period</td>
            <td>For those with specific needs that are not represented in our other plans or for big events with greater needs

              Get in touch for a tailor-made offer!</td>
          </tr>
          <tr>
            <td>Users</td>
            <td>1</td>
            <td>4</td>
            <td>4</td>
            <td>4</td>
            <td>4</td>
            <td>Custom</td>
          </tr>
          <tr>
            <td>Events</td>
            <td>Not restriced</td>
            <td>1</td>
            <td>5</td>
            <td>10</td>
            <td>10</td>
            <td>Custom</td>
          </tr>
          <tr>
            <td>Entries per event</td>
            <td>up to 75</td>
            <td>up to 500</td>
            <td>up to 500</td>
            <td>up to 250</td>
            <td>up to 500</td>
            <td>custom</td>
          </tr>
          <tr>
            <td>Valid for</td>
            <td>-</td>
            <td>one event</td>
            <td>one month</td>
            <td>one month</td>
            <td>90 days</td>
            <td>custom</td>
          </tr>
          <tr>
            <td>Basic list</td>
            <td><Icon name="check" /></td>
            <td><Icon name="check" /></td>
            <td><Icon name="check" /></td>
            <td><Icon name="check" /></td>
            <td><Icon name="check" /></td>
            <td><Icon name="check" /></td>
          </tr>
          <tr>
            <td>Access system use</td>
            <td>-</td>
            <td><Icon name="check" /></td>
            <td><Icon name="check" /></td>
            <td><Icon name="check" /></td>
            <td><Icon name="check" /></td>
            <td><Icon name="check" /></td>
          </tr>
          <tr>
            <td>Unit and group use</td>
            <td>-</td>
            <td><Icon name="check" /></td>
            <td><Icon name="check" /></td>
            <td><Icon name="check" /></td>
            <td><Icon name="check" /></td>
            <td><Icon name="check" /></td>
          </tr>
          <tr>
            <td>Event dashboard with statistics</td>
            <td>-</td>
            <td><Icon name="check" /></td>
            <td><Icon name="check" />
              <p>
              <span class="text-small">(+ statistics across events)</span>
              </p>
            </td>
            <td><Icon name="check" />
              <p>
                <span class="text-small">(+ statistics across events)</span>
              </p>
            </td>
            <td><Icon name="check" />
              <p>
                <span class="text-small">(+ statistics across events)</span>
              </p>
            </td>
            <td><Icon name="check" />
              <p>
                <span class="text-small">(+ statistics across events)</span>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              User log
            </td>
            <td>-</td>
            <td><Icon name="check" /></td>
            <td><Icon name="check" /></td>
            <td><Icon name="check" /></td>
            <td><Icon name="check" /></td>
            <td><Icon name="check" /></td>
          </tr>
          </tbody>
        </table>
        *Prices only include using the program, staff not included
        *Additional features like extra users and entries available for purchase
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import Icon from "@/components/Icon";
import Modal from "@/components/Modal";
import {selectFreePlan, selectPaymentPlan} from "@/api";
import router from "@/router";

export default {
  name: "AccountPaymentPlanSelect",
  components: {
    Navbar, Icon, Modal
  },
  data() {
    return {
      plans: [],
      users: [],
      plan: {},
      selectFreePlanModal: false,
      selectPaymentPlanModal: false,
      selectCustomPlanModal: false,
      planName: '',
      name: '',
      email: '',
      numberOfGuests: '',
      companyName: '',
      errorMessage: ''
    }
  },
  async mounted() {
  },
  methods: {
    openSelectFreePlan() {
      this.selectFreePlanModal = true
    },
    closeSelectFreePlan() {
      this.selectFreePlanModal = false
    },
    selectFreePaymentPlan() {
      this.selectFreePlanModal = false
      selectFreePlan()
      router.push('/account-payment-plan')
    },
    openSelectPaymentPlan(name) {
      this.planName = name
      this.selectPaymentPlanModal = true
    },
    closeSelectPlan() {
      this.selectPaymentPlanModal = false
    },
    openSelectPlanCustomPlan() {
      this.selectCustomPlanModal = true
    },
    selectCustomPlan() {

    },
    selectPaymentPlan() {
      this.selectPaymentPlanModal = false
      selectPaymentPlan(this.planName)
      router.push('/account-payment-plan')
    }
  },
}
</script>

<style scoped>

</style>
