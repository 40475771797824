<template>
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-12 col-md-8 col-lg-6 col-xl-5">
        <div class="card shadow-2-strong" style="border-radius: 1rem;">
          <div v-if="foundUser === true" class="card-body p-5 text-center">
            <div v-if="alreadyHasPassword">
              <h3>Password for this user has already been set</h3>
            </div>
            <div>
              <form v-on:submit.prevent="setPassword">
                <h3 class="mb-5">Set your password</h3>
                <div class="form-outline mb-4">
                  <label class="form-label" for="password">Password</label>
                  <input type="password" id="password" v-model="password" class="form-control form-control-lg"/>
                </div>
                <div class="form-outline mb-4">
                  <label class="form-label" for="password">Re-enter password</label>
                  <input type="password" id="password2" v-model="password2" class="form-control form-control-lg"/>
                  <p v-if="!passwordsMatch" class="text-danger">Passwords don't match</p>
                </div>
                <button class="btn btn-primary btn-block" type="submit">Set password and log in</button>
              </form>
            </div>
          </div>
          <div v-if="foundUser === false" class="card-body p-5 text-center">
            <h3>User not found</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import Navbar from '@/components/Navbar.vue'
import store from "@/store";
import {addArtist, getEventArtis, getUserByKey, setArtistUserPassword} from "@/artists_api";
import {getEvent} from "@/api";
import ArtistsMenu from "@/components/ArtistsMenu";

export default {
  name: 'SetArtist',
  components: {},
  props: ['eventId'],
  data() {
    return {
      email: '',
      password: '',
      password2: '',
      secret: null,
      foundUser: null,
      passwordsMatch: true,
      alreadyHasPassword: false
    }
  },
  async mounted() {
    this.secret = this.$route.query.key
    let found = await getUserByKey(this.secret)
    this.foundUser = found.found === true;
    if (this.foundUser) {
      this.alreadyHasPassword = found.message
    }
  },
  methods: {
    async setPassword() {
      if (this.password !== this.password2) {
        this.passwordsMatch = false
        return
      }
      let result = await setArtistUserPassword({
        link: this.secret, password: this.password
      })
      if (!result.ok) {
        return
      }
      await axios.post('/api/auth', {username: result.email, password: this.password})
        .then(result => {
          store.saveJwt(result.data.access_token)

          let artists = store.getArtists()

          if (artists) {
            if (artists.length === 1) {
              this.$router.replace({name: 'ArtistAdvancingForm'})
            } else {
              this.$router.replace({name: 'ArtistSelectArtist'})
            }
          }
        })
    }
  }
}
</script>
