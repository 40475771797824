<template>
  <div>
    <Navbar :event="event" :eventId="eventId" currentPage="Artists"/>
    <ArtistsMenu :eventId="eventId" active="general-information" :event="event" v-if="event">
      <div v-if="event" class="d-flex flex-row mt-3">
        <div class="flex-grow-1">
          <Breadcrumbs :event="event" currentPageName="General information"/>
        </div>
      </div>
      <h2 class="mb-5">General information</h2>
      <p>
        General information:
        <span v-if="event.general_information_published">published</span>
        <span v-else>not published</span>
      </p>
      <div class="h-75" v-if="event && editing">
        <el-tiptap class="w-100 h-100" v-model="general_information" :extensions="extensions"/>
        <div class="mt-2 clearfix d-flex justify-content-center">
          <Button :loading="loading" @click="saveGeneralInformation()">Save</Button>
          <Button :loading="loading" @click="saveAndPublish()">Save and publish</Button>
          <button class="btn btn-secondary" @click="editing=false">Cancel</button>
        </div>
      </div>
      <div v-else-if="event && !editing">
        <div v-html="general_information"></div>
        <Button @click="editing = true">Edit general information</Button>
      </div>
    </ArtistsMenu>
  </div>
</template>
<script>
import Navbar from '@/components/Navbar.vue'
import {getEvent, getEventData} from "@/api";
import Breadcrumbs from "@/components/Breadcrumbs";
import ArtistsMenu from "@/components/ArtistsMenu";
import {getEventArtists, getEventGeneralInformation, saveEventGeneralInformation} from "@/artists_api";
import {
  // necessary extensions
  Doc,
  Text,
  Link,
  Paragraph,
  Heading,
  Bold,
  Underline,
  Italic,
  Strike,
  ListItem,
  BulletList,
  HorizontalRule,
  OrderedList,
} from 'element-tiptap';
import Button from "@/components/Button";
import store from "@/store";

export default {
  name: 'ArtistsGeneralInformation',
  components: {
    ArtistsMenu, Button,
    Navbar, Breadcrumbs

  },
  props: ['eventId'],
  data() {
    return {
      editor: null,
      general_information: null,
      event: null,
      loading: false,
      editing: false,
      extensions: [
        new Doc(),
        new Text(),
        new Link(),
        new Paragraph(),
        new Heading({level: 5}),
        new Bold({bubble: true}), // render command-button in bubble menu.
        new Underline({bubble: true, menubar: false}), // render command-button in bubble menu but not in menubar.
        new Italic(),
        new Strike(),
        new ListItem(),
        new BulletList(),
        new OrderedList(),
        new HorizontalRule()
      ],
    }
  },
  methods: {
    saveGeneralInformation() {
      this.loading = true
      saveEventGeneralInformation(this.eventId, {'general_information': this.general_information})
        .finally(() => {
          this.loading = false
          this.editing = false
          store.addAlert("success", "Saved")
        })
    },
    saveAndPublish() {
      this.loading = true
      saveEventGeneralInformation(this.eventId,
        {
          'general_information': this.general_information,
          'publish': true
        })
        .finally(() => {
          this.loading = false
          this.editing = false
          this.event.general_information_published = true
          store.addAlert("success", "Published")
        })
    }
  },
  async mounted() {
    await getEventGeneralInformation(this.eventId).then(result => {
      this.event = result.event
      this.general_information = result.general_information
    })
  }
}
</script>
