<template>
  <div id="" class="container">
    <Navbar></Navbar>
    <template v-if="loaded">
      <div class="row">
        <div class="col-6">
          <h5>Your events</h5>
          <table class="table">
            <tr v-for="event of events" v-bind:key="event.id">
              <td>
                <router-link :to="{name: 'Event', params: { id: event.id }}" class="nav-top-link">
                  {{event.name}}
                </router-link>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import axios from 'axios'
import store from '../store'
import Navbar from '@/components/Navbar.vue'

export default {
  name: 'UserHome',
  props: [],
  components: {
    Navbar,
  },
  data () {
    return {
      units: [],
      groups: [],
      events: [],
      loaded: false,
      headers: {
        "Authorization": "Bearer " + store.jwt()
      }
    }
  },
  methods: {

  },
  async mounted() {
    const result = await axios.get("/api/user_dashboard", { headers: this.headers })
    this.events = result.data.events
    this.groups = result.data.groups
    this.units = result.data.units
    this.loaded = true

    let events = store.getEventUsers()

    if (events.length === 1) {
      if (events[0].role === 'security') {
        console.log('turva')
      } else if (events[0].role === 'check-in') {
        console.log('check in')
      }
    }
  }
}
</script>
