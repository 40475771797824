<template>
  <div class="min-vh-100 d-flex flex-column">
    <div class="flex-fill pb-5" id="app">
      <router-view/>
      <portal-target name="modals-container" :multiple="true"/>
    </div>
    <div class="mt-auto text-center py-2" style="border-top: 1px solid black; background-color: #FAF4E4;">
      Mistin <span class="text-small">(beta)</span>
    </div>
    <Toasts />
  </div>
</template>
<script>
import Toasts from "@/components/Toasts";
export default {
  components: {Toasts}
}
</script>
