<template>
  <div>
    <ArtistNavbar :event="event" currentPage="Artists"/>

    <div v-if="artist && form && event">
      <ArtistPageMenu :event="event" currentPage="Form">
        <h1>{{ artist.name }}</h1>
        <p v-if="artist.form_submitted">Your form has been submitted!</p>
        <ArtistData v-if="form" :artist="artist" :eventId="eventId" :event="event" :form="form" :artistId="artistId"/>
        <div v-if="!artist.form_submitted" class="mt-2 d-flex justify-content-center w-100">
          <button type="button" class="me-2 btn btn-secondary">Save</button>
          <button type="button" @click="saveAndSubmit()" class="ms-2 btn btn-primary">Save and submit</button>
        </div>
      </ArtistPageMenu>
    </div>
  </div>
</template>
<script>
import ArtistNavbar from '@/components/ArtistNavbar.vue'
import {
  getArtistPage, saveAndSubmitArtist,
} from "@/artists_api";
import store from "@/store";
import dayjs from 'dayjs'
import ArtistPageMenu from "@/components/ArtistPageMenu";
import ArtistData from "@/components/ArtistData";

export default {
  name: 'ArtistAdvancingForm',
  components: {ArtistPageMenu, ArtistNavbar, ArtistData},
  props: [],
  data() {
    return {
      artistId: null,
      artist: null,
      eventId: null,
      event: null,
      form: null,
    }
  },
  async mounted() {
    this.artistId = store.getArtist()
    await getArtistPage(this.artistId).then(result => {
      this.artist = result.artist
      this.event = result.event
      this.eventId = this.event.id
      this.form = result.artist.form
    })
  },
  methods: {
    saveAndSubmit() {
      if (window.confirm("After submitting the form you can't make any more changes, are you sure?")) {
        saveAndSubmitArtist(this.eventId, this.artistId)
        this.artist.form_submitted = true
      }
    }
  },
}
</script>
