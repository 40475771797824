<template>
  <div>
    <Navbar/>
    <AccountAdminMenu>
      <div>
        <h1>Account admin</h1>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card mt-3 card-body" v-for="user in users" v-bind:key="user.id">
            <div v-if="editingUser && editingUser.id === user.id" class="row">
              <div class="col-4">
                <div class="item-title">
                  Name
                </div>
                <div>
                  <input type="text" v-model="editingUserName" class="form-control"/>
                </div>
              </div>
              <div class="col-4">
                <div class="item-title">
                  E-mail
                </div>
                <div>
                  <input type="text" v-model="editingUserEmail" class="form-control"/>
                </div>
              </div>
              <div class="col-2">
                <div class="item-title">
                  Role
                </div>
                <div>
                  <select class="form-select" v-model="editingUserRole">
                    <option :value="null">Choose role</option>
                    <option v-for="role in roles" :value="role.name" v-bind:key="role.name">{{ role.title }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-2">
                <div class="item-title">&nbsp;</div>
                <div>
                  <button @click="updateUser(user)" class="btn btn-primary me-2"><Icon name="check" width="15" height="15" /></button>
                  <button @click="cancelEditing" class="btn btn-primary"><Icon name="close" width="15" height="15"/></button>
                </div>
              </div>
            </div>
            <div v-else class="row">
              <div class="col-4">
                <div class="item-title">
                  Name
                </div>
                <div>
                  {{ user.name }}
                </div>
              </div>
              <div class="col-4">
                <div class="item-title">
                  E-mail
                </div>
                <div>
                  {{ user.e_mail }}
                </div>
              </div>
              <div class="col-2">
                <div class="item-title">
                  Role
                </div>
                <div>
                  {{ roleName(user.role) }}
                </div>
              </div>
              <div class="col-2">
                <div class="item-title">&nbsp;
                </div>
                <div>
                  <button class="me-2 btn btn-primary" @click="editUser(user)">
                    <Icon name="edit" width="15" height="15"/>
                  </button>
                  <button class="btn btn-primary" @click="deleteUser(user.id)">
                    <Icon name="trash" width="15" height="15"/>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="card mt-3">
            <div class="card-body">
              <div v-if="plan && users.length < plan.user_limit">
                <h3>Add user</h3>
                <div class="row mb-3">
                  <div class="col-auto">
                    <div class="item-title mb-1">
                      Name
                    </div>
                    <div>
                      <div class="row">
                        <div class="col-auto">
                          <input type="text" class="form-control pe-0" v-model="newUserName"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-auto">
                    <div class="item-title mb-1">
                      E-mail
                    </div>
                    <div>
                      <div class="row">
                        <div class="col-auto">
                          <input type="text" class="form-control pe-0" v-model="newUserMail"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-auto">
                    <div class="item-title mb-1">
                      Password
                    </div>
                    <div>
                      <div class="row">
                        <div class="col-auto">
                          <input type="password" class="form-control pe-0" v-model="newUserPassword"/>
                        </div>
                      </div>
                    </div>
                    <div class="col-auto">
                      <div class="item-title mb-1">
                        Re-enter password
                      </div>
                      <div>
                        <div class="row">
                          <div class="col-auto">
                            <input type="password" class="form-control pe-0" v-model="newUserPassword2"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-auto">
                    <div class="item-title mb-1">
                      Role
                    </div>
                    <div>
                      <div class="row">
                        <div class="col-auto">
                          <select class="form-select" v-model="newUserRole">
                            <option :value="null">Choose role</option>
                            <option v-for="role in roles" :value="role.name" v-bind:key="role.name">{{ role.title }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button type="button" @click="addUser()" class="btn btn-primary">Add user</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AccountAdminMenu>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import {addAccountUser, deleteAccountUser, getAccountUsers, getPaymentPlans, updateAccountUser} from "@/api";
import store from "@/store";
import AccountAdminMenu from "@/components/AccountAdminMenu";
import Icon from "@/components/Icon";

export default {
  name: "AccountAdmin",
  components: {
    Navbar, AccountAdminMenu, Icon
  },
  // https://dev.to/codeply/bootstrap-5-sidebar-examples-38pb
  data() {
    return {
      plans: [],
      users: [],
      roles: [{name: 'owner', title: 'Top dog'}, {name: 'manager', title: 'Manager'}, {
        name: 'worker_bee', title: 'Worker bee'
      }, {name: 'worker_bee+', title: 'Worker bee+'},
        {'name': 'artist_manager', title: 'Artist manager'}],
      newUserName: '',
      newUserMail: '',
      newUserPassword: '',
      newUserPassword2: '',
      newUserRole: null,
      editingUser: null,
      editingUserName: null,
      editingUserEmail: null,
      editingUserRole: null,
      plan: {}
    }
  },
  async mounted() {
    this.plans = await getPaymentPlans()
    this.users = await getAccountUsers()
    this.plan = store.getPlan()
  },
  methods: {
    editUser(user) {
      this.editingUser = user
      this.editingUserEmail = user.e_mail
      this.editingUserName = user.name
      this.editingUserRole = user.role
    },
    cancelEditing() {
      this.editingUser = null
      this.editingUserEmail = null
      this.editingUserName = null
      this.editingUserRole = null
    },
    updateUser(user) {
      updateAccountUser(this.editingUser.id, {
        'name': this.editingUserName, 'email': this.editingUserEmail, 'role': this.editingUserRole
      }).then(() => {
        store.addAlert("success", "User updated")
        this.editingUser.e_mail = this.editingUserEmail
        this.editingUser.name = this.editingUserName
        this.editingUser.role = this.editingUserRole
        this.cancelEditing()
      })
    },
    deleteUser(userId) {
      if (confirm("Are you sure you want to delete this user?")) {
        deleteAccountUser(userId).then(() => {
          store.addAlert("success", "User deleted")
          this.users = this.users.filter(u => u.id !== userId)
        })
      }
    },
    async addUser() {
      if (this.newUserPassword2 !== this.newUserPassword) {
        alert("Passwords do not match")
        return
      }
      await addAccountUser({'user_email': this.newUserMail, 'name': this.newUserName, 'role': this.newUserRole, 'password': this.newUserPassword})
      this.newUserRole = null
      this.newUserName = ''
      this.newUserMail = ''
      this.newUserPassword = ''
      this.newUserPassword2 = ''
      this.users = await getAccountUsers()
    },
    roleName(role) {
      if (role === 'owner') {
        return 'Top dog'
      } else if (role === 'manager') {
        return 'Manager'
      } else if (role === 'worker_bee')
        return 'Worker bee'
      else if (role === 'worker_bee+') {
        return 'Worker bee+'
      } else if (role === 'artist_manager')
        return 'Artist manager'
    }
  },
}
</script>
