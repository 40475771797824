<template>
  <div>
    <Navbar :event="event" :eventId="id" currentPage="Event"></Navbar>
    <div id="" class="container-fluid">
      <div v-if="event">
        <div class="row">
          <div class="col-12">
            <h1>{{ event.name }}</h1>
            <div v-if="guest_limit && guest_limit.limit">
              {{ guest_limit.guest_count || '0' }} of {{ guest_limit.limit }} guests
            </div>
            <table class="table w-50">
              <thead>
              <tr>
                <th>Unit</th>
                <th>Group</th>
                <th>Total</th>
                <th>Checked in</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>Total:</td>
                <td></td>
                <td>{{totals['guests']}}</td>
                <td>{{totals['checked_in']}}</td>
              </tr>
              <template v-for="(s, index) in stats">
                <tr v-bind:key="index">
                  <td>{{ s.unit_name }}</td>
                  <td></td>
                  <td>{{ s.guest_count }}</td>
                  <td>{{ s.checked_in_count }}</td>
                </tr>
                <tr v-for="group in s.groups" v-bind:key="group.id + '-group'">
                  <td></td>
                  <td>{{ group.name }}</td>
                  <td>{{ group.guest_count }}</td>
                  <td>{{ group.checked_in_count }}</td>
                </tr>
              </template>
              </tbody>
            </table>

            <ActivityLogs :logs="logs" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Navbar from '@/components/Navbar.vue'
import {getEvent, getEventCheckInStats, getEventLogs} from "@/api";
import dayjs from "dayjs";
import ActivityLogs from "@/components/ActivityLogs";

export default {
  name: 'Event',
  props: ['id'],
  components: {
    ActivityLogs,
    Navbar,
  },
  data() {
    return {
      event: null,
      logs: [],
      stats: [],
      totals: null,
      guest_limit: null
    }
  },
  async mounted() {
    const result = await getEvent(this.id)
    this.event = result.event
    this.guest_limit = result.guest_limit
    await getEventCheckInStats(this.id).then(result => {
      this.stats = result.units
      this.totals = result.totals
    })
    this.logs = await getEventLogs(this.id)
  }
}
</script>
