<template>
  <div>
    <ArtistNavbar currentPage="Artists"/>

      <ArtistPageMenu v-if="event" :event="event" currentPage="MyAccount">
        <h1>My account</h1>

        <ChangePassword />

      </ArtistPageMenu>
    </div>
</template>
<script>
import ArtistNavbar from '@/components/ArtistNavbar.vue'
import ArtistPageMenu from "@/components/ArtistPageMenu";
import store from "@/store";
import {getArtistPage} from "@/artists_api";
import ChangePassword from "@/components/ChangePassword";

export default {
  name: 'ArtistMyAccount',
  components: {ChangePassword, ArtistPageMenu, ArtistNavbar},
  props: [],
  data() {
    return {
      artist: null,
      event: null,
      form: null
    }
  },
  async mounted() {
    this.artistId = store.getArtist()
    await getArtistPage(this.artistId).then(result => {
      this.artist = result.artist
      this.event = result.event
      this.eventId = this.event.id
      this.form = result.artist.form
    })
  }
}
</script>
