<template>
  <div>
    <ArtistNavbar currentPage="Artists"/>

    <ArtistPageMenu v-if="event" :event="event" currentPage="Schedule">
      <h1>Schedule</h1>

      <div v-if="!event.schedule_published" class="d-flex justify-content-center w-100 mt-5">
        <h4 class="text-uppercase mt-5">Informative content coming soon, stay tuned!</h4>
      </div>

      <div v-else-if="artist">
        <div v-for="schedule in schedules" v-bind:key="'schedule-'+schedule.id">
          <div class="mt-3 row">
            <h4>{{ schedule.name }}</h4>
            <div class="row mb-3">
            </div>
            <template v-for="stage in stages">
              <div v-bind:key="'stage-'+stage.id" class="col-md-4 mt-2 mb-5">
                <strong class="mb-5">{{ stage.name }}</strong>
                <br/>
                <template v-for="a in schedule.artists">
                  <div v-if="a.stage_id === stage.id" v-bind:key="'a-' + a.id"
                       :class="{'text-danger': a.artist_id === artist.id, 'fw-bolder': a.artist_id === artist.id}">
                    {{ a.start_time }}&#8212;{{ a.end_time }} {{ getArtist(a.artist_id).name }}
                  </div>
                </template>
              </div>
            </template>
          </div>
          <hr/>
        </div>
      </div>
    </ArtistPageMenu>
  </div>
</template>
<script>
import ArtistNavbar from '@/components/ArtistNavbar.vue'
import ArtistPageMenu from "@/components/ArtistPageMenu";
import store from "@/store";
import {getArtistPage, getEventArtists, getEventSchedules, getStages} from "@/artists_api";
import {getEventData} from "@/api";

export default {
  name: 'ArtistPageTimetable',
  components: {ArtistPageMenu, ArtistNavbar},
  props: [],
  data() {
    return {
      artist: null,
      event: null,
      form: null,
      stages: [],
      schedules: [],
      artists: [],
    }
  },
  methods: {
    getArtist(artistId) {
      return this.artists.filter(a => a.id === artistId)[0]
    }
  },
  async mounted() {
    this.artistId = store.getArtist()
    await getArtistPage(this.artistId).then(result => {
      this.artist = result.artist
      this.event = result.event
      this.eventId = this.event.id
      this.form = result.artist.form
    })
    this.artists = await getEventArtists(this.eventId)
    this.schedules = await getEventSchedules(this.eventId)
    this.stages = await getStages(this.eventId)
  }
}
</script>
