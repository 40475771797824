<template>
  <div class="card mt-3 card-body col-6">
    <form v-on:submit.prevent="changePassword">
      <div class="col-8">
        <div class="item-title">
          Current password
        </div>
        <div>
          <input class="form-control" type="password" v-model="current_password"/>
        </div>
      </div>
      <div class="col-8 mt-3">
        <div class="item-title">
          New password
        </div>
        <div>
          <input class="form-control" type="password" v-model="new_password"/>
        </div>
      </div>
      <div class="col-8 mt-3">
        <div class="item-title">
          Re-enter new password
        </div>
        <div>
          <input class="form-control" type="password" v-model="new_password2"/>
        </div>
      </div>
      <div class="text-danger mt-2" v-if="passwords_dont_match">
        Passwords don't match
      </div>
      <div class="text-danger mt-2" v-if="message">
        {{message}}
      </div>
      <button type="submit" class="btn btn-primary mt-3">Change password</button>
    </form>
  </div>
</template>

<script>
import {changePassword} from "@/api";

export default {
  name: "ChangePassword",
  data() {
    return {
      plans: [],
      users: [],
      plan: {},
      current_password: '',
      new_password: '',
      new_password2: '',
      passwords_dont_match: false,
      message: null
    }
  },
  methods: {
    async changePassword() {
      if (this.new_password === this.new_password2) {
        let result = await changePassword(this.current_password, this.new_password)
        this.passwords_dont_match = false
        if (result['ok']) {
          this.password_changed = true
          this.message = result['message']
        } else {
          this.message = result['message']
        }
      } else {
        this.passwords_dont_match = true
        this.message = null
      }
    }
  },
}
</script>
