import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import PortalVue from 'portal-vue'
import dayjs from 'dayjs'

import './assets/scss/main.scss'

Vue.use(PortalVue)
Vue.filter('formatDateTime', function (value) {
  if (!value || value.length === 0)
    return ''
  return dayjs(value).format('DD.MM.YYYY HH:mm')
})
Vue.filter('formatDate', function (value) {
  if (!value || value.length === 0)
    return ''
  return dayjs(value).format('DD.MM.YYYY')
})
Vue.filter('formatTime', function (value) {
  if (!value || value.length === 0)
    return ''
  return dayjs(value).format('HH:mm')
})
Vue.filter('round100', function(value) {
  return naiveRound(value, 1)
})

function naiveRound(num, decimalPlaces = 0) {
  var p = Math.pow(10, decimalPlaces);
  return Math.round(num * p) / p;
}

import ElementUI from 'element-ui';
import { ElementTiptapPlugin } from 'element-tiptap';
// import ElementUI's styles
import 'element-ui/lib/theme-chalk/index.css';
// import this package's styles
import 'element-tiptap/lib/index.css';

// use ElementUI's plugin
Vue.use(ElementUI);
// use this package's plugin
Vue.use(ElementTiptapPlugin, {
  lang: "en", // see i18n
  spellcheck: false, // can be overwritten by editor prop
});

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
