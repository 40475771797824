<template>
  <div
    v-bind:class="[person.checked_in_count === person.guest_count ? '' : '', 'd-flex flex-row card mt-3 card-body p-0']">
    <div class="w-100 p-3">
      <div v-on:click="openDetails" class="row">
        <div class="col-3">
          <div class="item-title">
            Name
          </div>
          <div class="body1">
            {{ person.name }}
          </div>
        </div>
        <div class="col-2">
          <div class="item-title">
            Extra
          </div>
          <div class="body1" v-if="person.guest_count > 1">+{{ person.guest_count - 1 }}</div>
        </div>
        <div v-if="onlyGuestlist" class="col-2">
        </div>
        <div v-else class="col-2">
          <div class="item-title">Unit</div>
          <div class="body1">{{ unit_names[person.unit_id] }}</div>
        </div>
        <div v-if="onlyGuestlist" class="col-2">
        </div>
        <div v-else class="col-2">
          <div class="item-title">Wristband</div>
          <div class="body1" v-if="person.wristband_id && wristbands.length > 0">
            {{ findWristbandName(person.wristband_id) }}
          </div>
        </div>
        <div class="col-3">
          <span v-if="openedItemId === this.person.id && person.guest_count > 1 && person.guest_count != person.checked_in_count">
            <button v-on:click.stop="checkInAll()" type="button" class="btn btn-primary btn-sm">Check in all</button>
          </span>
        </div>
      </div>
      <div class="row mt-1" v-if="openedItemId === this.person.id">
        <div class="col-3">
          <div class="item-title">Comment</div>
          <div class="body1">
            {{ person.comments }}
          </div>
        </div>
        <div class="col-2">
          <div class="item-title">Checked in</div>
          <div class="body1">{{ person.checked_in_count }}/{{ person.guest_count }}</div>
        </div>
        <div v-if="onlyGuestlist" class="col-2">
        </div>
        <div v-else class="col-2">
          <div class="item-title">Group</div>
          <div class="body1">{{ person.group_id != null ? group_names[person.group_id] : '' }}</div>
        </div>
        <div class="col-2">
          <div v-if="person.badge_id && badges.length > 0" class="item-title">
            Badge
          </div>
          <div class="body1" v-if="person.badge_id && badges.length > 0">
            {{ findBadgeName(person.badge_id) }}
          </div>
        </div>
        <div class="col-3 body1">
          <div class="float-left" v-if="person.checked_in_count < person.guest_count">
            <span v-if="person.guest_count > 1">
              <button @click="checkIn()" type="button" class="btn btn-primary btn-sm me-1">Check in one</button>
            </span>
              <span v-else>
              <button type="button" @click="checkIn()" class="btn btn-primary">Check in</button>
            </span>
          </div>
          <div class="float-left mt-1" v-if="person.checked_in_count > 0">
            <button type="button" class="btn btn-primary btn-sm" @click="removeCheckIn()">Remove check in</button>
          </div>
        </div>
      </div>
    </div>
    <div v-on:click="openDetails" class="mh-100 flex-shrink-1 border-start d-flex align-items-center">
      <Icon v-if="openedItemId === this.person.id" name="shrink" class="m-3"/>
      <Icon v-else name="expand" class="m-3"/>
    </div>
  </div>
</template>

<script>
import {checkInAllGuests, checkInPerson, removeCheckIn} from "@/api";
import Icon from "@/components/Icon";
import store from "@/store";

export default {
  name: "PersonCheckInItem",
  components: {
    Icon,
  },
  props: [
    'person', 'unit_names', 'group_names', 'wristbands', 'eventId', 'openedItemId', 'badges',
    'checkedIn', 'onlyGuestlist'
  ],
  methods: {
    findWristbandName(wristbandId) {
      if (this.wristbands === undefined || this.wristbands === null || this.wristbands.length === 0)
        return ''
      else {
        let wristband = this.wristbands.find(w => {
          return w.id == wristbandId
        })
        if (wristband != undefined) {
          return wristband.name
        }
      }
    },
    findBadgeName(badgeId) {
      if (this.badges === undefined || this.badges === null || this.badges.length === 0)
        return ''
      else {
        let badge = this.badges.find(b => {
          return b.id == badgeId
        })
        if (badge != undefined) {
          return badge.name
        }
      }
    },
    checkIn() {
      this.person.checked_in = true
      try {
        checkInPerson(this.eventId, {'person_id': this.person.id}).then(r => {
          this.person.checked_in_count = this.person.checked_in_count + 1
          store.addAlert("success", "Checked in one")
        }).catch(error => {
          this.person.checked_in_count = this.person.checked_in_count + 1
          console.log("Catch: ", error)
          console.log("Adding to checkin retries")
          store.addCheckinRetry(this.eventId, {'person_id': this.person.id})
        })
      } catch (error) {
        this.person.checked_in_count = this.person.checked_in_count + 1
        console.log("Try-catch", error)
        console.log("Adding to checkin retries")
        store.addCheckinRetry(this.eventId, {'person_id': this.person.id})
      }
    },
    async removeCheckIn() {
      this.person.checked_in = false
      removeCheckIn(this.eventId, {'person_id': this.person.id}).then(result => {
        store.addAlert("success", `Removed check-in`)
        this.person.checked_in_count = this.person.checked_in_count - 1
      })
    },
    checkInAll() {
      checkInAllGuests(this.eventId, {'person_id': this.person.id}).then(r => {
        this.person.checked_in_count = this.person.guest_count
      }).then(result => store.addAlert("success", "Checked in all guests"))
        .catch(error => console.log(error))
    },
    openDetails() {
      this.$emit('opened', this.person.id)
    }
  },
  mounted() {
  },
}
</script>
