<template>
  <div>
    <ArtistNavbar currentPage="Artists"/>

    <ArtistPageMenu v-if="event" :event="event" currentPage="Accomodation">
      <h1>Accomodation</h1>

      <div v-if="!event.accomodation_published" class="d-flex justify-content-center w-100 mt-5">
        <h4 class="text-uppercase mt-5">Informative content coming soon, stay tuned!</h4>
      </div>
      <div v-else>
        <div v-if="artist && hotels.length > 0">
          <div class="row">
            <div class="col item-title">
              Hotel
            </div>
            <div class="col item-title">
              Address
            </div>
            <div class="col item-title">
              Check-in
            </div>
            <div class="col item-title">
              Check-out
            </div>
          </div>
          <div class="row">
            <div class="col">
              {{ getHotel(artist.hotel.id).name }}
            </div>
            <div class="col">
              {{ getHotel(artist.hotel.id).address }}
            </div>

            <div class="col">
              {{ getHotel(artist.hotel.id).check_in }}
            </div>
            <div class="col">
              {{ getHotel(artist.hotel.id).check_out }}
            </div>
          </div>
          <div class="row mt-3">
            <div class="col item-title">
              From
            </div>
            <div class="col item-title">
              To
            </div>
            <div class="col item-title">
              Breakfast
            </div>
            <div class="col item-title">
              <span v-if="artist.hotel.comments">Comments</span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              {{ artist.hotel.from | formatDate }}
            </div>
            <div class="col">
              {{ artist.hotel.to | formatDate }}
            </div>
            <div class="col">
              {{ getHotel(artist.hotel.id).breakfast }}
            </div>
            <div class="col">
              {{artist.hotel.comments}}
            </div>
          </div>
        </div>
      </div>
    </ArtistPageMenu>
  </div>
</template>
<script>
import ArtistNavbar from '@/components/ArtistNavbar.vue'
import ArtistPageMenu from "@/components/ArtistPageMenu";
import store from "@/store";
import {getArtistPage, getEventHotels} from "@/artists_api";

export default {
  name: 'ArtistPageAccomodation',
  components: {ArtistPageMenu, ArtistNavbar},
  props: [],
  data() {
    return {
      artist: null,
      event: null,
      form: null,
      hotels: []
    }
  },
  methods: {
    getHotel(hotelId) {
      return this.hotels.find(hotel => hotel.id === hotelId)
    }

  },
  async mounted() {
    this.artistId = store.getArtist()
    await getArtistPage(this.artistId).then(result => {
      this.artist = result.artist
      this.event = result.event
      this.eventId = this.event.id
      this.form = result.artist.form
    })
    this.hotels = await getEventHotels(this.eventId)
  }
}
</script>
