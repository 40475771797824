<template>
  <nav class="navbar navbar-expand px-4">
    <router-link :to="{name: 'Home'}" class="navbar-brand" href="#">
      <span class="brand">MISTIN</span>| <span class="account-name">{{ account_name }}</span>
    </router-link>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav  mx-auto">
        <li class="nav-item">
          <router-link :to="{name: 'Events'}"
                       :class="[currentPage !== 'Events' ? 'text-disabled' : 'menu-active', 'nav-link']" href="#">
            <span class="nav-text">Home</span>
          </router-link>
        </li>
        <li v-if="hasRole('superuser')" class="nav-item">
          <router-link :to="{name: 'AllEvents'}"
                       :class="[currentPage !== 'AdminEvents' ? 'text-disabled' : 'menu-active', 'nav-link']" href="#">
            <span class="nav-text">All events</span>
          </router-link>
        </li>
        <li v-if="event && hasAccess('event')" class="nav-item">
          <router-link :to="{name: 'Event', params: { id: event.id }}"
                       :class="[currentPage !== 'Event' ? 'text-disabled' : 'menu-active', 'nav-link']">
            Dashboard
          </router-link>
        </li>
        <li v-if="event && hasAccess('access-system')" class="nav-item">
          <router-link :to="{name: 'AccessSystem', params: { eventId: event.id }}"
                       :class="[currentPage !== 'AccessSystem' ? 'text-disabled' : 'menu-active', 'nav-link']" href="#">
            Access system
          </router-link>
        </li>
        <li v-if="event && hasAccess('people')" class="nav-item">
          <router-link :to="{name: 'EventPeople', params: { eventId: event.id }}"
                       :class="[currentPage !== 'People' ? 'text-disabled' : 'menu-active', 'nav-link']" href="#">
            People
          </router-link>
        </li>
        <li v-if="event && hasAccess('artists')" class="nav-item">
          <router-link :to="{name: 'Artists', params: { eventId: event.id }}"
                       :class="[currentPage !== 'Artists' ? 'text-disabled' : 'menu-active', 'nav-link']" href="#">
            Artists
          </router-link>
        </li>
        <li v-if="event && hasAccess('check-in')" class="nav-item">
          <router-link :to="{name: 'CheckIn', params: { eventId: event.id }}"
                       :class="[currentPage !== 'CheckIn' ? 'text-disabled' : 'menu-active', 'nav-link']" href="#">
            Check in
          </router-link>
        </li>
        <li v-if="event && hasAccess('event-users')" class="nav-item">
          <router-link :to="{name: 'EventUsers', params: { eventId: event.id }}"
                       :class="[currentPage !== 'EventUsers' ? 'text-disabled' : 'menu-active', 'nav-link']" href="#">
            Event users
          </router-link>
        </li>
      </ul>
    </div>
    <div class="navbar-nav account d-flex  align-items-center">
        <router-link :to="{name: 'AccountSettings'}" class="nav-link">My account</router-link>
          <span>|</span>
        <a href="#" class="nav-link" @click.prevent="logout()">Log out</a>
    </div>
  </nav>
</template>
<script>
import axios from 'axios'
import store from '../store'

export default {
  name: 'Navbar',
  props: ['event', 'eventId', 'currentPage'],
  mounted() {
    this.account_name = localStorage.getItem("ACCOUNT_NAME")
  },
  data() {
    return {
      account_name: null
    }
  },
  methods: {
    async logout() {
      store.logOut()
      const result = await axios.post("/api/logout")
      this.$router.replace({name: 'Login'})
    },
    hasRole(role) {
      let roles = store.getRoles()
      if (!roles) return false

      if (roles.includes(role))
        return true
      else
        return false
    },
    hasAccess(page) {
      let event_users = store.getEventUsers()
      if (event_users.length > 0) {
        if (event_users[0].role === 'worker_bee' && page === "check-in") {
          return true
        }
        if (event_users[0].role === 'worker_bee+' && (page === "check-in" || page === 'people')) {
          return true
        }
      }

      if (page === 'access-system' && store.isOnlyGuestlist()) {
        return false
      }
      let roles = store.getRoles()
      if (this.eventId) {
        this.event_id = parseInt(this.eventId, 10)
        let r = event_users.find(f => f.event_id === this.event_id)
        if (r) {
          roles.push(r.role)
        }
      }
      if (['people', 'access-system', 'artists', 'event'].includes(page) && roles.includes('worker_bee')) {
        return false
      }
      if (['access-system', 'artists', 'event'].includes(page) && roles.includes('worker_bee+')) {
        return false
      }

      if (['check-in', 'people', 'access-system'].includes(page) && roles.includes('artist_manager')) {
        return false
      }

      if (page === 'event-users' && !(roles.includes('manager') || roles.includes('owner'))) {
        return false
      }

      if (page === 'artists') {
        if (roles.includes("worker_bee+") || roles.includes("worker_bee"))
          return false
        else
          return true
      }

      return true

    }
  },
}
</script>
