<template>
  <div>
    <Navbar :event="event" :eventId="eventId" currentPage="Artists"/>
    <div v-if="artist">
      <ArtistsMenu :eventId="eventId" active="artist" :event="event" v-if="event">
        <h1>{{ artist.name }}</h1>
        <div v-if="form">
          <ArtistData :artist="artist" :eventId="eventId" :event="event" :form="form" :artistId="artistId"/>
          <div v-if="!artist.form_submitted" class="mt-2 d-flex justify-content-center w-100">
            <button type="button" @click="saveAndSubmit()" class="ms-2 btn btn-primary">Save and submit</button>
          </div>
          <div v-else class="mt-2 d-flex justify-content-center w-100">
            <button type="button" @click="removeSaveAndSubmit()" class="ms-2 btn btn-primary">Set form not submitted</button>
          </div>
        </div>
        <h1 v-else="">No form selected</h1>
      </ArtistsMenu>
    </div>
  </div>
</template>
<script>
import Navbar from '@/components/Navbar.vue'
import {
  addArtistBackstageGuest,
  addArtistContact,
  addArtistCrew, addArtistGaGuest,
  addArtistPerformer, addArtistRiderFile,
  getEventArtis, saveAndSubmitArtist, setArtistNotSubmitted
} from "@/artists_api";
import {getAdvancingForm, getEvent, getEventData} from "@/api";
import ArtistData from "@/components/ArtistData";
import ArtistsMenu from "@/components/ArtistsMenu";


export default {
  name: 'AddArtist',
  components: {ArtistsMenu, Navbar, ArtistData},
  props: ['eventId', 'artistId'],
  data() {
    return {
      artist: null,
      event: null,
      form: null
    }
  },
  methods: {
    removeSaveAndSubmit() {
      setArtistNotSubmitted(this.eventId, this.artistId).then(() => {
        this.artist.form_submitted = false
        store.addAlert("success", "Artist form set to not submitted")
      })
    },
    saveAndSubmit() {
      if (window.confirm("After submitting the form you can't make any more changes, are you sure?")) {
        saveAndSubmitArtist(this.eventId, this.artistId)
        this.artist.form_submitted = true
      }
    }
  },
  async mounted() {
    this.artist = await getEventArtis(this.eventId, this.artistId)
    this.event = await getEventData(this.eventId)
    if (this.artist.advancing_form_id != null) {
      this.form = this.artist.form
    } else {
      this.form = null
    }
  }
}
</script>
