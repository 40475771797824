<template>
  <div>
    <Navbar :event="event" :eventId="eventId" currentPage="Artists"/>
    <ArtistsMenu :eventId="eventId" active="accomodation" :event="event" v-if="event">
      <div v-if="event" class="d-flex flex-row mt-3">
        <div class="flex-grow-1">
          <Breadcrumbs :event="event" currentPageName="Accomodation"/>
        </div>
      </div>

      <div class="d-flex justify-content-between mb-5">
        <h2>Accomodation</h2>
        <div>
          <button class="btn btn-primary">
            <router-link class="text-light text-decoration-none" :to="{name: 'AddHotel'}">
              Add hotel
            </router-link>
          </button>
        </div>
      </div>

      <div v-for="hotel in hotels" v-bind:key="'hotel-' + hotel.id" class="card card-body mb-3">
        <div class="row">
          <div class="col item-title">
            Hotel
          </div>
          <div class="col item-title">
            Address
          </div>
          <div class="col item-title">
            Check-in
          </div>
          <div class="col item-title">
            Check-out
          </div>
        </div>
        <div class="row">
          <div class="col">
            {{ hotel.name }}
          </div>
          <div class="col">
            {{ hotel.address }}
          </div>

          <div class="col">
            {{ hotel.check_in }}
          </div>
          <div class="col">
            {{ hotel.check_out }}
          </div>
        </div>
        <div class="row mt-3">
          <div class="col item-title">
            Breakfast
          </div>
          <div class="col"></div>
        </div>
        <div class="row">
          <div class="col">
            {{ hotel.breakfast }}
          </div>
          <div class="col">
          </div>
        </div>
      </div>

      <div class="mt-3 d-flex justify-content-center">
        <button v-if="event.accomodation_published" @click="unpublish" class="btn btn-primary">Unpublish
          accomodation
        </button>
        <button v-else class="btn btn-primary" @click="publish">Publish accomodation</button>
      </div>

      <h3>Artists</h3>

      <div v-if="hotels.length > 0">
        <div class="card card-body mt-3" v-for="artist in artists" v-bind:key="'artist-' + artist.id">
          <div class="row">
            <div class="item-title col">Name</div>
            <div class="item-title col">Hotel</div>
            <div class="item-title col">From</div>
            <div class="item-title col">To</div>
            <div class="col"></div>
          </div>
          <div class="row">
            <div class="col">{{ artist.name }}</div>
            <div class="col">{{ artist.hotel ? getHotelName(artist.hotel.id) : 'Not set' }}</div>
            <div class="col text-small">{{ (artist.hotel ? artist.hotel.from : '') | formatDate }}</div>
            <div class="col text-small">{{ (artist.hotel ? artist.hotel.to : '') | formatDate }}</div>

            <div class="col">
              <button v-if="editingArtist !== artist.id" class="btn btn-primary" @click="editArtist(artist.id)">
                <Icon name="edit" width="15" height="15"/>
              </button>
            </div>
          </div>
          <div v-if="artist.hotel && artist.hotel.comments" class="row">
            <div class="item-title col">Comments</div>
          </div>
          <div v-if="artist.hotel && artist.hotel.comments" class="row">
            <div class="col">
              {{ artist.hotel ? artist.hotel.comments : '' }}
            </div>
          </div>
          <div v-if="artist.id === editingArtist" class="row mt-2">
            <div class="item-title col">Hotel</div>
            <div class="item-title col">From</div>
            <div class="item-title col">To</div>
            <div class="item-title col"></div>
            <div class="item-title col"></div>
          </div>
          <div v-if="artist.id === editingArtist" class="row mt-2">
            <div class="col">
              <select class="form-select" v-model="selectedHotelId">
                <option disabled :value="null">Select hotel</option>
                <option v-for="hotel in hotels" :value="hotel.id" v-bind:key="'hotel-'+hotel.id">{{
                    hotel.name
                  }}
                </option>
              </select>
            </div>
            <div class="col"><input type="date" v-model="fromDate" class="form-control"/></div>
            <div class="col"><input type="date" v-model="toDate" class="form-control"/></div>
            <div class="col">
              <button class="btn btn-primary" @click="saveHotel()">
                <Icon name="check" width="15" height="15"/>
              </button>
              <button class="ms-2 btn btn-primary" @click="closeEditing()">
                <Icon name="close" width="15" height="15"/>
              </button>
            </div>
          </div>
          <div v-if="artist.id === editingArtist">
            <div class="row mt-3">
              <div class="item-title col">
                Comments
              </div>
            </div>
            <div class="row">
              <div class="col"><textarea cols="50" rows="2" v-model="comments"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ArtistsMenu>
  </div>
</template>
<script>
import Navbar from '@/components/Navbar.vue'
import {
  getEventArtists,
  getEventHotels,
  saveArtistHotel,
  unpublishAccomodation,
  publishAccomodation
} from "@/artists_api";
import {getEvent, getEventData} from "@/api";
import Breadcrumbs from "@/components/Breadcrumbs";
import ArtistsMenu from "@/components/ArtistsMenu";
import Icon from "@/components/Icon";
import store from "@/store";

export default {
  name: 'ArtistsAccomodation',
  components: {
    ArtistsMenu, Icon,
    Navbar, Breadcrumbs

  },
  props: ['eventId'],
  data() {
    return {
      artists: [],
      event: null,
      hotels: [],
      editingArtist: null,
      selectedHotelId: null,
      fromDate: null,
      toDate: null,
      comments: ''
    }
  },
  async mounted() {
    this.artists = await getEventArtists(this.eventId)
    this.event = await getEventData(this.eventId)
    this.hotels = await getEventHotels(this.eventId)
  },
  methods: {
    async saveHotel() {
      let artist = this.artists.find(artist => artist.id === this.editingArtist)
      artist.hotel = {'id': this.selectedHotelId, 'from': this.fromDate,
        'to': this.toDate, 'comments': this.comments}
      await saveArtistHotel(this.eventId, this.editingArtist, artist.hotel)

      this.editingArtist = null
      this.selectedHotelId = null
      this.fromDate = null
      this.toDate = null
      this.comments = ''
    },
    editArtist(artistId) {
      this.editingArtist = artistId
      let artist = this.artists.find(artist => artist.id === artistId)
      if (artist.hotel) {
        this.selectedHotelId = artist.hotel.id
        this.fromDate = artist.hotel.from
        this.toDate = artist.hotel.to
        this.comments = artist.hotel.comments
      }
    },
    getHotelName(hotelId) {
      let hotel = this.hotels.find(hotel => hotel.id === hotelId)
      return hotel.name
    },
    closeEditing() {
      this.editingArtist = null
      this.selectedHotelId = null
      this.fromDate = null
      this.toDate = null
      this.comments = ''
    },
    publish() {
      publishAccomodation(this.eventId).then(() => {
        this.event.accomodation_published = true
        store.addAlert("success", "Accomodation published")
      })
    },
    unpublish() {
      unpublishAccomodation(this.eventId).then(() => {
        this.event.accomodation_published = false
        store.addAlert("success", "Accomodation unpublished")
      })
    }
  },
}
</script>
