function parseJwt (token) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))
  return JSON.parse(jsonPayload)
}

const JWT = 'JWT'
const EVENT = 'EVENT'
const ACCOUNT_ID = 'ACCOUNT_ID'

const store = {
  state: {
    toastMessages: [],
  },
  saveJwt (jwt) {
    jwt && localStorage.setItem(JWT, jwt)
    const token = jwt && parseJwt(jwt)
    const user = token && token.user
    user && localStorage.setItem('USER', JSON.stringify(user))
    localStorage.setItem('EVENT_USERS', JSON.stringify(token.event_users))
    localStorage.setItem('EVENT', JSON.stringify(token.event_users))
    localStorage.setItem('ROLES', JSON.stringify(token.roles))
    localStorage.setItem('ACCOUNT_ID', token.account_id)
    localStorage.setItem('PLAN', JSON.stringify(token.plan))
    localStorage.setItem('ACCOUNT_NAME', token.account_name)
    if (token.artists && token.artists.length > 0) {
      localStorage.setItem('CURRENT_ARTIST', token.artists[0].id)
      localStorage.setItem('ARTISTS', JSON.stringify(token.artists))
    }

  },
  getArtist() {
    return localStorage.getItem('CURRENT_ARTIST')
  },
  setArtist(id) {
    localStorage.setItem('CURRENT_ARTIST', id)
  },
  getArtists() {
    return JSON.parse(localStorage.getItem('ARTISTS'))
  },
  jwt() {
    return localStorage.getItem(JWT);
  },
  getUser() {
    return JSON.parse(localStorage.getItem('USER'))
  },
  getEventUsers() {
    return JSON.parse(localStorage.getItem('EVENT_USERS'))
  },
  getRoles() {
    return JSON.parse(localStorage.getItem('ROLES'))
  },
  getPlan() {
    return JSON.parse(localStorage.getItem('PLAN'))
  },
  isOnlyGuestlist() {
    return this.getPlan().only_guestlist
  },
  parseJwt(token) {
      let base64Url = token.split('.')[1];
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload);
  },
  logOut() {
    localStorage.clear()
  },
  setEvent(event) {
    localStorage.setItem(EVENT, event)
  },
  getEvent() {
    localStorage.getItem(EVENT)
  },
  getAccountId() {
    localStorage.getItem(ACCOUNT_ID)
  },
  addAlert(type, title, autoDismiss, content) {
    let toast = {
      type: type,
      title: title,
      content: content
    };
    if (autoDismiss === undefined) {
      autoDismiss = type !== 'danger'
    }
    if (this.hasDuplicates(toast)) {
      autoDismiss = true;
    }
    if (autoDismiss) {
      const dismissTimeout = type === 'success' ? 4000 : 7000
      setTimeout(() => this.dismissAlert(toast), dismissTimeout)
    }
    this.state.toastMessages.push(toast)
  },
  areToastsEqual(toast1, toast2) {
    return toast1.type === toast2.type && toast1.title === toast2.title && toast1.content === toast2.content
  },
  hasDuplicates(toast) {
    return !!store.state.toastMessages.find(t => this.areToastsEqual(t, toast))
  },
  dismissAlert(toast) {
    store.state.toastMessages = store.state.toastMessages.filter(t => t !== toast)
  },
  dismissAllAlerts() {
    this.state.toastMessages = []
  },

  setCheckinRetries(items) {
    localStorage.setItem('CHECKIN_RETRY', JSON.stringify(items))
  },
  addCheckinRetry(eventId, body) {
    let items = this.getCheckinRetries()
    items.push({'eventId': eventId, 'body': body})
    localStorage.setItem('CHECKIN_RETRY', JSON.stringify(items))
  },
  getCheckinRetries() {
    let stored = localStorage.getItem('CHECKIN_RETRY')
    if (stored === null) {
      stored = '[]'
    }
    return JSON.parse(stored)
  }
}

export default store

window.api = {
  store,
}
