<template>
  <div>
    <h1 class="mb-5">Access system</h1>
    <ModalArea ref="openedAreaModal" :eventId="eventId" v-on:deleted="removeObject"/>
    <div class="card border-2">
      <div class="card-body pt-4">
        <div class="row">
          <div class="col-4">
            <div class="item-title mb-2">
              Areas
            </div>
            <div class="row">
              <div class="col-auto">
                <input type="text" @keypress.enter="addArea()" placeholder="Area name" class="mb-2 form-control pe-0"
                       v-model="areaName"/>
              </div>
              <div class="col-auto p-0">
                <button class="btn btn-primary btn-small py-2 px-3" @click="addArea">Add type</button>
              </div>
            </div>
          </div>
          <div class="col-8">
            <div class="item-title mb-2">
              Areas
            </div>
            <button v-for="area in accessSystem.areas" :key="area.id"
                    class="me-2 mb-2 btn btn-light pill-lg rounded-pill py-2 px-3"
                    @click="openArea(area)">
              <Icon iconClass="me-2" name="edit"/>
              {{ area.name }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card mt-3 border-2">
      <div class="card-body pt-4">
        <div class="row">
          <div class="col-4">
            <div class="item-title mb-2">
              Times
            </div>
            <div>
              <div class="row">
                <div class="col-auto">
                  <input type="text" @keypress.enter="addTime()" placeholder="Time name" class="mb-2 form-control pe-0"
                         v-model="timeName"/>
                </div>
                <div class="col-auto p-0">
                  <button class="btn btn-primary py-2 px-3" @click="addTime">Add type</button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-8 mb-2">
            <div class="item-title">
              Times
            </div>
            <div>
              <button v-for="time in accessSystem.times" :key="time.id"
                      class="me-2 mb-2 btn btn-light rounded-pill py-2 px-3"
                      @click="openTime(time)">
                <Icon iconClass="mb-2" name="edit"/>
                {{ time.name }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card mt-3 border-2">
      <div class="card-body pt-4">
        <div class="row">
          <div class="col-4">
            <div class="item-title mb-2">
              Profiles
            </div>
            <div class="row">
              <div class="col-auto">
                <input type="text" @keypress.enter="addProfile()" placeholder="Profile name"
                       class="mb-2 form-control pe-0" v-model="profileName"/>
              </div>
              <div class="col-auto p-0">
                <button class="btn btn-primary py-2 px-3" @click="addProfile">Add type</button>
              </div>
            </div>
          </div>
          <div class="col-8">
            <div class="item-title mb-2">
              Profiles
            </div>
            <div>
              <button v-for="profile in accessSystem.profiles" :key="profile.id"
                      class="me-2 mb-2 btn btn-light rounded-pill py-2 px-3" @click="openProfile(profile)">
                <Icon iconClass="me-2" name="edit"/>
                {{ profile.name }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card mt-3 border-2">
      <div class="card-body pt-4">
        <div class="row">
          <div class="col-4">
            <div class="item-title mb-2">
              Wristbands
            </div>
            <div>
              <div class="row">
                <div class="col-auto">
                  <input type="text" @keypress.enter="addWristband()" placeholder="Wristband name"
                         class="mb-2 form-control pe-0" v-model="wristbandName"/>
                </div>
                <div class="col-auto p-0">
                  <button class="btn btn-primary py-2 px-3" @click="addWristband">Add type</button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-8">
            <div class="item-title mb-2">
              Wristbands
            </div>
            <div>
              <button v-for="wristband in accessSystem.wristbands" :key="wristband.id"
                      class="me-2 mb-2 btn btn-light rounded-pill py-2 px-3" @click="openWristband(wristband)">
                <Icon iconClass="" name="edit"/>
                {{ wristband.name }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card mt-3 border-2">
      <div class="card-body pt-4">
        <div class="row">
          <div class="col-4">
            <div class="item-title mb-2">
              Badges
            </div>
            <div class="row">
              <div class="col-auto">
                <input type="text" @keypress.enter="addBadge()" placeholder="Badge name" class="mb-2 form-control pe-0"
                       v-model="badgeName"/>
              </div>
              <div class="col-auto p-0">
                <button class="btn btn-primary btn-small py-2 px-3" @click="addBadge">Add type</button>
              </div>
            </div>
          </div>
          <div class="col-8">
            <div class="item-title mb-2">
              Badges
            </div>
            <div>
              <button v-for="badge in accessSystem.badges" :key="badge.id"
                      class="me-2 mb-2 btn btn-light rounded-pill py-2 px-3"
                      @click="openBadge(badge)">
                <Icon iconClass="" name="edit"/>
                {{ badge.name }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card mt-3 border-2">
      <div class="card-body">
        <div class="row mt-3">
          <div class="col-4">
            <div class="item-title mb-2">
              Upload media
            </div>
            <div>
              <label class="btn btn-primary" for="file-upload">Choose</label>
              <input id="file-upload" type="file" class="d-none" ref="file" @change="pickFile($event)" accept="image/*"
                     :name="uploadFieldName"/>
              <span class="ms-3" style="color: #666666">{{
                  uploadFieldName ? uploadFieldName : 'No file selected'
                }}</span>
            </div>
          </div>
          <div class="col-3">
            <div class="item-title mb-2">
              Choose type
            </div>
            <div class="ms-0 d-flex flex-row">
              <label class="pt-1 ms-0 me-5" for="typeWristband">
                <input class="me-2" id="typeWristband" type="radio" v-on:change="changeType('wristband')"
                       v-model="uploadType" value="wristband"/>Wristband</label>
              <label class="pt-1" for="typeBadge">
                <input class="me-2" id="typeBadge" type="radio" v-on:change="changeType('badge')"
                       v-model="uploadType" value="badge"/>Badge</label>
            </div>
          </div>
          <div class="col-3">
            <div class="item-title mb-2">
              Choose type
            </div>
            <div>
              <select class="form-select" v-model="uploadedTypeId">
                <option v-for="o in options" :value="o.id" v-bind:key="o.id">{{ o.name }}</option>
              </select>
            </div>
          </div>
          <div class="col-2">
            <div class="item-title mb-2">&nbsp;</div>
            <div class="text-end">
              <button class="btn btn-primary" v-on:click="uploadPicture()">Upload media</button>
            </div>
          </div>
        </div>

        <div class="row p-0" v-if="wristbandsWithPicture.length > 0">
          <div class="col-6 mt-4">
            <div class="item-title">
              Wristbands
            </div>
            <div class="mt-3 card border-2" v-for="w in wristbandsWithPicture" v-bind:key="w.id">
              <div class="card-body">
                <div class="row">
                  <div class="col-8" @click="showPictureModal(w.picture_url)">
                    <img style="height: 64px; width: 400px" class="img-fluid wristband-pilt" :src="w.picture_url"/>
                  </div>
                  <div class="col-4">
                    <div class="item-title">
                      Name
                    </div>
                    <div>
                      {{ w.name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-6 mt-4" v-if="badgesWithPicture.length > 0">
            <div class="item-title">
              Badges
            </div>
            <div class="mt-3 card border-2" v-for="w in badgesWithPicture" v-bind:key="w.id">
              <div class="card-body">
                <div class="row">
                  <div class="col-8" @click="showPictureModal(w.picture_url)">
                    <img style="height: 64px; width: 400px" class="img-fluid wristband-pilt" :src="w.picture_url"/>
                  </div>
                  <div class="col-4">
                    <div class="item-title">
                      Name
                    </div>
                    <div>
                      {{ w.name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h3 class="mt-5">ACCESS MATRIX</h3>
    <table class="table mt-3 access_matrix">
      <tbody>
      <tr>
        <td style="border: none">
        </td>
        <td style="border: none" class="text-center" v-for="area in accessSystem.areas" :key="area.id">{{
            area.name
          }}
        </td>
        <td style="border: none" class="text-center">Times</td>
        <td v-if="accessSystem.wristbands.length > 0" style="border: none" class="text-center">Wristband</td>
        <td v-if="accessSystem.badges.length > 0" style="border: none" class="text-center">Badge</td>
      </tr>

      <tr v-for="profile in accessSystem.profiles" :key="profile.id">
        <td style="border: none" class="text-center">{{ profile.name }}</td>
        <td class="text-center access-matrix" v-for="area in accessSystem.areas" :key="area.id">
          <input type="checkbox" v-model="profile.areas" :value="area.id" :id="'profile-area-'+profile.id+'-'+area.id"
                 @click="updateProfileArea(profile.id, area.id)"/>
        </td>
        <td class="text-center access-matrix">
          <select class="form-select" v-model="profile.timeId">
            <option selected :value="null">Select a time</option>
            <option v-for="time in accessSystem.times" :value="time.id" :key="time.id">{{ time.name }}</option>
          </select>
        </td>
        <td v-if="accessSystem.wristbands.length > 0" class="text-center access-matrix">
          <select class="form-select" v-model="profile.wristbandId">
            <option selected :value="null">Select a wristband</option>
            <option v-for="wristband in accessSystem.wristbands" :value="wristband.id" :key="wristband.id">
              {{ wristband.name }}
            </option>
          </select>
        </td>
        <td v-if="accessSystem.badges.length > 0" class="access-matrix text-center">
          <select class="form-select" v-model="profile.badgeId">
            <option selected :value="null">Select a badge</option>
            <option v-for="badge in accessSystem.badges" :value="badge.id" :key="badge.id">{{ badge.name }}</option>
          </select>
        </td>
      </tr>
      </tbody>
    </table>
    <div class="d-flex justify-content-center my-5">
      <button class="btn btn-primary" @click="saveAccess()">Save</button>
    </div>
    <Modal v-bind:show-modal.sync="showPicture" :modal-class="'modal-full-screen modal-dialog-centered'">
      <div class="modal-content">
        <div class="modal-body">
          <div class="modal-content overflow-auto" style="max-width: 70vw">
            <div class="modal-body">
              <img :src="showUrl" style="max-height: 70vh"/>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary"
                      data-dismiss="modal"
                      aria-label="Close"
                      @click="showPicture = false">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import ModalArea from "@/components/ModalArea";
import axios from 'axios'
import store from '../store'
import {addEventArea, addEventBadge, addEventProfile, addEventTime, addEventWristband, saveAccessSystem} from "@/api";
import Icon from "@/components/Icon";
import Modal from "@/components/Modal";

export default {
  name: 'AccessSystemEditing',
  components: {
    ModalArea, Icon, Modal
  },
  data() {
    return {
      openedArea: null,

      areaName: '',
      timeName: '',
      profileName: '',
      wristbandName: '',
      badgeName: '',

      uploadFieldName: '',
      uploadType: null,
      uploadWristbandId: null,
      uploadBadgeId: null,
      uploadedTypeId: null,
      options: [],
      showPicture: false,
      showUrl: ''
    }
  },
  props: [
    'accessSystem', 'eventId'
  ],
  methods: {
    async uploadPicture() {
      const formData = new FormData();
      formData.append('file', this.$refs.file.files[0])
      formData.append('uploadType', this.uploadType)
      formData.append('uploadedTypeId', this.uploadedTypeId)
      const headers = {
        'Content-Type': 'multipart/form-data',
        "Authorization": "Bearer " + store.jwt(),
        "Accept": "application/json"
      };
      const result = await axios.post(`/api/event/${this.eventId}/upload_file`, formData, {headers})
      if (result.status === 200) {
        if (this.uploadType === 'wristband') {
          let w = this.accessSystem.wristbands.find(w => w.id === this.uploadedTypeId)
          w.picture_url = result.data.picture_url
        }
      }
    },
    pickFile(e) {
      // https://blog.openreplay.com/building-a-custom-file-upload-component-for-vue/
      // Check if file is selected


      let input = this.$refs.file
      let file = input.files
      if (e.target.files && e.target.files[0]) {
        file = e.target.files[0]
        this.uploadFieldName = file.name
      }
      if (file && file[0]) {
        let reader = new FileReader
        reader.onload = e => {
          this.previewImage = e.target.result
        }
        reader.readAsDataURL(file[0])
        this.$emit('input', file[0])
      }
    },
    changeType(typeName) {
      this.uploadType = typeName
      if (typeName === 'wristband')
        this.options = this.accessSystem.wristbands
      else
        this.options = this.accessSystem.badges
    },
    showPictureModal(url) {
      this.showPicture = true
      this.showUrl = url
    },
    async addArea() {
      if (this.areaName.length == 0)
        return

      const result = await addEventArea(this.eventId, {name: this.areaName})
      this.accessSystem.areas.push(result)
      this.areaName = ''
    },
    async addTime() {
      if (this.timeName.length == 0)
        return

      const result = await addEventTime(this.eventId, {name: this.timeName})
      this.accessSystem.times.push(result)
      this.timeName = ''
    },
    async addProfile() {
      if (this.profileName.length == 0)
        return

      const result = await addEventProfile(this.eventId, {name: this.profileName})
      this.accessSystem.profiles.push(result)
      this.profileName = ''
    },
    async addWristband() {
      if (this.wristbandName.length == 0)
        return

      const result = await addEventWristband(this.eventId, {name: this.wristbandName})
      this.accessSystem.wristbands.push(result)
      this.wristbandName = ''
    },
    async addBadge() {
      if (this.badgeName.length == 0)
        return

      const result = await addEventBadge(this.eventId, {name: this.badgeName})
      this.accessSystem.badges.push(result)
      this.badgeName = ''
    },
    updateProfileArea(profile, area) {
    },
    async saveAccess() {
      const result = await saveAccessSystem(this.accessSystem.event.id, {profiles: this.accessSystem.profiles})
      this.$router.go()
    },
    openArea(area) {
      this.$refs.openedAreaModal.present(area, "area")
    },
    openTime(time) {
      this.$refs.openedAreaModal.present(time, "time")
    },
    openProfile(profile) {
      this.$refs.openedAreaModal.present(profile, "profile")
    },
    openBadge(badge) {
      this.$refs.openedAreaModal.present(badge, "badge")
    },
    openWristband(wristband) {
      this.$refs.openedAreaModal.present(wristband, "wristband")
    },
    removeObject(area, objectType) {
      if (objectType === 'area') {
        this.accessSystem.areas = this.accessSystem.areas.filter(a => a !== area)
      } else if (objectType === 'time') {
        this.accessSystem.times = this.accessSystem.times.filter(a => a !== area)
      } else if (objectType === 'profile') {
        this.accessSystem.profiles = this.accessSystem.profiles.filter(profile => profile !== area)
      } else if (objectType === 'badge') {
        this.accessSystem.badges = this.accessSystem.badges.filter(profile => profile !== area)
      } else if (objectType === 'wristband') {
        this.accessSystem.wristbands = this.accessSystem.wristbands.filter(profile => profile !== area)
      }
    }
  },
  computed: {
    wristbandsWithPicture() {
      return this.accessSystem.wristbands.filter(w => w.picture_url)
    },
    badgesWithPicture() {
      return this.accessSystem.badges.filter(w => w.picture_url)
    }
  }
}
</script>
