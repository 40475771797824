<template>
  <div>
    <div v-if="'Intro' in form.sections" class="mt-4">
      <h4 class="text-uppercase">{{ form.sections['Intro'].name }}</h4>
      <div class="d-flex justify-content-between">
        <div class="" v-html="form.sections['Intro'].message">
        </div>
      </div>
    </div>

    <div class="mt-5" v-if="'Contacts' in form.sections">
      <h4 class="text-uppercase">{{ form.sections['Contacts'].name }}</h4>
      <div class="d-flex justify-content-between">
        <div v-html="form.sections['Contacts'].message">
        </div>
        <div>
          <div class="subtitle2 border rounded border-primary p-2">
            DEADLINE: {{ artist.contact_deadline | formatDate }}
          </div>
        </div>
      </div>

      <div class="card p-3 my-2 container">
        <template v-for="contact in artist.contacts">
          <div class="row mt-2" v-bind:key="'contact1-'+contact.id">
            <div class="item-title col">Name</div>
            <div class="item-title col">Job title</div>
            <div class="item-title col">E-mail</div>
            <div class="item-title col">Phone</div>
            <div class="item-title col"></div>
          </div>
          <div class="row" v-bind:key="'contact2-'+contact.id">
            <div class="col"><input class="form-control" :disabled="editingContact!==contact.id"
                                    v-model="contact.name"/></div>
            <div class="col"><input class="form-control" :disabled="editingContact!==contact.id"
                                    v-model="contact.job_title"></div>
            <div class="col"><input class="form-control" :disabled="editingContact!==contact.id" type="email"
                                    v-model="contact.email"/></div>
            <div class="col"><input class="form-control" :disabled="editingContact!==contact.id" type="tel"
                                    v-model="contact.phone"/></div>

              <div v-if="contactsAllowed && !artist.form_submitted && editingContact!==contact.id" class="col text-end">
                <button class="btn btn-primary me-2" @click="toggleEditingContact(contact.id)">
                  <Icon name="edit" width="15" height="15"/>
                </button>
                <button class="btn btn-primary" @click="removeContact(contact.id)">
                  <Icon name="trash" width="15" height="15"/>
                </button>
              </div>
              <div v-else-if="contactsAllowed && !artist.form_submitted" class="col text-end">
                <button class="btn btn-primary me-2" @click="saveEditedContact(contact.id)">
                  <Icon name="check" width="15" height="15"/>
                </button>
                <button class="btn btn-primary" @click="cancelEditingContact(contact.id)">
                  <Icon name="close" width="15" height="15"/>
                </button>
              </div>
              <div v-else class="col text-end"></div>
          </div>
        </template>

        <div v-if="showAddContact">
          <div class="row mt-2">
            <div class="item-title col">Name</div>
            <div class="item-title col">Job title</div>
            <div class="item-title col">E-mail</div>
            <div class="item-title col">Phone</div>
            <div class="item-title col"></div>
          </div>
          <div class="row">
            <div class="col"><input type="text" class="form-control" v-model="contactName"/></div>
            <div class="col"><input type="text" class="form-control" v-model="contactJobTitle"></div>
            <div class="col"><input type="email" class="form-control" v-model="contactEmail"/></div>
            <div class="col"><input type="tel" class="form-control" v-model="contactPhone"/></div>
            <div class="col text-end">
              <button class="btn btn-primary" @click="addContact()">
                <div v-if="addContactLoading" class="spinner-border-sm text-success" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <Icon v-else name="check" width="15" height="15"/>
              </button>
            </div>
          </div>
        </div>
        <div v-if="contactsAllowed && !artist.form_submitted" class="mt-3">
          <button v-if="!showAddContact" class="btn btn-primary mt-2 " @click="showAddContact = true">Add contact
          </button>
          <button v-if="showAddContact" class="btn btn-primary mt-2 " @click="closeAddContact()">
            <Icon name="close" width="15" height="15"/>
          </button>
        </div>
      </div>
    </div>

    <div class="mt-5" v-if="'Performers' in form.sections">
      <h4 class="text-uppercase">{{ form.sections['Performers'].name }}</h4>
      <div class="d-flex p-0 m-0 justify-content-between">
        <div class="col-9" v-html="form.sections['Performers'].message">
        </div>
        <div>
          <div class="flex-grow-1 d-flex">
            <div class="subtitle2 me-2 flex-grow-1 border rounded border-primary p-2">
              DEADLINE: {{ artist.performers_deadline | formatDate }}
            </div>
            <div class="subtitle2 flex-grow-1 border rounded border-primary p-2">
              LIMITS: {{ artist.performers.length }}&nbsp;/&nbsp;{{ artist.performers_limit || '0' }}
            </div>
          </div>
        </div>
      </div>
      <div class="card p-3 my-2 container">
        <template v-for="performer in artist.performers">
          <div class="row mt-2" v-bind:key="'performer1-'+performer.id">
            <div class="item-title col">Name</div>
            <div class="item-title col">Job title</div>
            <div class="item-title col"></div>
          </div>
          <div class="row" v-bind:key="'performer2-'+performer.id">
            <div class="col"><input class="form-control" :disabled="editingPerformer!==performer.id"
                                    v-model="performer.name"/></div>
            <div class="col"><input class="form-control" :disabled="editingPerformer!==performer.id"
                                    v-model="performer.job_title"></div>
            <div v-if="performersAllowed &&!artist.form_submitted && editingPerformer!==performer.id" class="col text-end">
              <button class="btn btn-primary me-2" @click="toggleEditingPerformer(performer.id)">
                <Icon name="edit" width="15" height="15"/>
              </button>
              <button class="btn btn-primary" @click="removePerformer(performer.id)">
                <Icon name="trash" width="15" height="15"/>
              </button>
            </div>
            <div v-else-if="performersAllowed && !artist.form_submitted" class="col text-end">
              <button class="btn btn-primary me-2" @click="saveEditedPerformer(performer.id)">
                <Icon name="check" width="15" height="15"/>
              </button>
              <button class="btn btn-primary" @click="cancelEditingPerformer(performer.id)">
                <Icon name="close" width="15" height="15"/>
              </button>
            </div>
            <div v-else class="col text-end"></div>
          </div>
        </template>

        <div v-if="performersAllowed && !artist.form_submitted && showAddPerformer" class="">
          <div class="row mt-2">
            <div class="item-title col">Name</div>
            <div class="item-title col">Job title</div>
            <div class="item-title col"></div>
          </div>
          <div class="row">
            <div class="col"><input class="form-control" v-model="performerName"/></div>
            <div class="col"><input class="form-control" v-model="performerJobTitle"></div>
            <div class="col text-end">
              <Button :loading="addPerformerLoading" @click="addPerformer()">
                <Icon name="check" width="15" height="15"/>
              </Button>
            </div>
          </div>
        </div>
        <div class="mt-3" v-if="performersAllowed && !artist.form_submitted && artist.performers.length < artist.performers_limit || 0">
          <button v-if="!showAddPerformer" class="btn btn-primary mt-2 " @click="showAddPerformer = true">Add
            performer
          </button>
          <button v-if="showAddPerformer" class="btn btn-primary mt-2 " @click="closeAddPerformer()">
            <Icon name="close" width="15" height="15"/>
          </button>
        </div>
      </div>
    </div>

    <div class="mt-5" v-if="'Crew' in form.sections">
      <h4 class="text-uppercase">{{ form.sections['Crew'].name }}</h4>
      <div class="d-flex justify-content-between">
        <div class="col-9" v-html="form.sections['Crew'].message">
        </div>
        <div>
          <div class="flex-grow-1 d-flex">
            <div class="subtitle2 me-2 flex-grow-1 border rounded border-primary p-2">
              DEADLINE: {{ artist.crew_deadline | formatDate }}
            </div>
            <div class="subtitle2 flex-grow-1 border rounded border-primary p-2">
              LIMITS: {{ artist.crew.length }}&nbsp;/&nbsp;{{ artist.crew_limit || '0' }}
            </div>
          </div>
        </div>
      </div>
      <div class="card p-3 my-2">
        <template v-for="crew in artist.crew">
          <div class="row mt-2" v-bind:key="'crew1-'+ crew.id">
            <div class="item-title col">Name</div>
            <div class="item-title col">Job title</div>
            <div class="item-title col">E-mail</div>
            <div class="item-title col">Phone</div>
            <div class="item-title col"></div>
          </div>
          <div class="row" v-bind:key="'crew2-'+ crew.id">
            <div class="col"><input class="form-control" :disabled="editingCrew!==crew.id" v-model="crew.name"/></div>
            <div class="col"><input class="form-control" :disabled="editingCrew!==crew.id" v-model="crew.job_title">
            </div>
            <div class="col"><input class="form-control" :disabled="editingCrew!==crew.id" type="email"
                                    v-model="crew.email"/></div>
            <div class="col"><input class="form-control" :disabled="editingCrew!==crew.id" type="tel"
                                    v-model="crew.phone"/></div>
            <div v-if="crewAllowed && !artist.form_submitted && editingCrew!==crew.id" class="col text-end">
              <button class="btn btn-primary me-2" @click="toggleEditingCrew(crew.id)">
                <Icon name="edit" width="15" height="15"/>
              </button>
              <button class="btn btn-primary" @click="removeCrew(crew.id)">
                <Icon name="trash" width="15" height="15"/>
              </button>
            </div>
            <div v-else-if="crewAllowed && !artist.form_submitted" class="col text-end">
              <button class="btn btn-primary me-2" @click="saveEditedCrew(crew.id)">
                <Icon name="check" width="15" height="15"/>
              </button>
              <button class="btn btn-primary" @click="cancelEditingCrew(crew.id)">
                <Icon name="close" width="15" height="15"/>
              </button>
            </div>
            <div v-else class="col text-end"></div>
          </div>
        </template>

        <div v-if="crewAllowed && !artist.form_submitted && showAddCrew">
          <div class="row mt-2">
            <div class="item-title col">Name</div>
            <div class="item-title col">Job title</div>
            <div class="item-title col">E-mail</div>
            <div class="item-title col">Phone</div>
            <div class="item-title col"></div>
          </div>
          <div class="row">
            <div class="col"><input class="form-control" v-model="crewName"/></div>
            <div class="col"><input class="form-control" v-model="crewJobTitle"></div>
            <div class="col"><input class="form-control" type="email" v-model="crewEmail"/></div>
            <div class="col"><input class="form-control" type="tel" v-model="crewPhone"/></div>
            <div class="col text-end">
              <button class="btn btn-primary" @click="addCrew()">
                <div v-if="addCrewLoading" class="spinner-border-sm text-success" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <Icon v-else name="check" width="15" height="15"/>
              </button>
            </div>
          </div>
        </div>
        <div class="mt-3" v-if="crewAllowed && !artist.form_submitted && artist.crew.length < artist.crew_limit || 0">
          <button v-if="!showAddCrew" class="btn btn-primary mt-2 " @click="showAddCrew = true">Add crew
          </button>
          <button v-if="showAddCrew" class="btn btn-primary mt-2 " @click="closeAddCrew()">
            <Icon name="close" width="15" height="15"/>
          </button>
        </div>
      </div>
    </div>

    <div class="mt-5" v-if="'Guestlist' in form.sections">
      <h4 class="text-uppercase">{{ form.sections['Guestlist'].name }}</h4>
      <div class="d-flex justify-content-between">
        <div class="col-9" v-html="form.sections['Guestlist'].message">
        </div>
        <div>
          <div class="flex-grow-1 d-flex">
            <div class="subtitle2 me-2 flex-grow-1 border rounded border-primary p-2">
              DEADLINE: {{ artist.guestlist_deadline | formatDate }}
            </div>
            <div class="subtitle2 flex-grow-1 border rounded border-primary p-2">
              LIMITS: {{ artist.guestlist.length }}&nbsp;/&nbsp;{{ artist.guestlist_limit || '0' }}
            </div>
          </div>
        </div>
      </div>
      <div class="card p-3 my-2">
        <template v-for="guestlist in artist.guestlist">
          <div class="row mt-2" v-bind:key="'guestlist1-'+guestlist.id">
            <div class="col item-title mb-2">Name</div>
            <div class="col item-title mb-2">Via</div>
            <div class="col"></div>
          </div>
          <div class="row" v-bind:key="'guestlist2-'+guestlist.id">
            <div class="col"><input class="form-control" :disabled="editingGuestlist!==guestlist.id"
                                    v-model="guestlist.name"/></div>
            <div class="col"><input class="form-control" :disabled="editingGuestlist!==guestlist.id"
                                    v-model="guestlist.via"></div>
            <div v-if="guestlistAllowed && !artist.form_submitted && editingGuestlist!==guestlist.id" class="col text-end">
              <button class="btn btn-primary me-2" @click="toggleEditingGuestlist(guestlist.id)">
                <Icon name="edit" width="15" height="15"/>
              </button>
              <button class="btn btn-primary" @click="removeGuest(guestlist.id)">
                <Icon name="trash" width="15" height="15"/>
              </button>
            </div>
            <div v-else-if="guestlistAllowed && !artist.form_submitted" class="col text-end">
              <button class="btn btn-primary me-2" @click="saveEditedGuestlist(guestlist.id)">
                <Icon name="check" width="15" height="15"/>
              </button>
              <button class="btn btn-primary" @click="cancelEditingGuestlist(guestlist.id)">
                <Icon name="close" width="15" height="15"/>
              </button>
            </div>
            <div v-else class="col text-end"></div>
          </div>
        </template>

        <div v-if="guestlistAllowed && !artist.form_submitted && showAddGuestlist" class="">
          <div class="row mt-2">
            <div class="col item-title mb-2">Name</div>
            <div class="col item-title mb-2">Via</div>
            <div class="col"></div>
          </div>

          <div class="row">
            <div class="col"><input class="form-control" v-model="guestlistName"/></div>
            <div class="col"><input class="form-control" type="email" v-model="guestlistVia"/></div>
            <div class="col text-end">
              <button class="btn btn-primary" @click="addGuest()">
                <div v-if="addGuestLoading" class="spinner-border-sm text-success" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <Icon name="check" width="15" height="15"/>
              </button>
            </div>
          </div>
        </div>
        <div class="mt-3" v-if="guestlistAllowed && !artist.form_submitted && artist.guestlist.length < artist.guestlist_limit || 0">
          <button v-if="!showAddGuestlist" class="btn btn-primary mt-2 " @click="showAddGuestlist = true">Add
            guest
          </button>
          <button v-if="showAddGuestlist" class="btn btn-primary mt-2 " @click="closeAddGuestlist()">
            <Icon name="close" width="15" height="15"/>
          </button>
        </div>
      </div>
    </div>

    <div class="mt-5" v-if="'Riders' in form.sections">
      <h4 class="text-uppercase">{{ form.sections['Riders'].name }}</h4>
      <div class="d-flex justify-content-between">
        <div>
          <div class="col-9" v-html="form.sections['Riders'].message">
          </div>
          <p>
            File size is limited to 10MB.
          </p>
        </div>
        <div class="">
          <div class="subtitle2 border rounded border-primary p-2">
            DEADLINE: {{ artist.rider_deadline | formatDate }}
          </div>
        </div>
      </div>
      <div class="card p-3 my-2">
        <div v-for="file in artist.rider_files" v-bind:key="'rider_files-'+file.id"
             class="mb-3 d-flex justify-content-between">
          <div class="">
            <div class="item-title mb-2">Name</div>
            <div><a :href="file.url" target="_blank">{{ file.filename }}</a></div>
          </div>
          <div v-if="ridersAllowed && !artist.form_submitted" class="d-flex align-items-end">
            <button @click="removeFile(file.id)" class="btn btn-primary">
              <Icon name="trash" width="15" height="15"/>
            </button>
          </div>
        </div>
        <div v-if="ridersAllowed && !artist.form_submitted" class="mt-3">
          <div class="row">
            <div class="col">
              <form v-on:submit.prevent="uploadRiderFile">
                <div class="item-title mb-2">
                  Choose file
                </div>
                <div class="row">
                  <div>
                    <input id="file-upload" type="file" ref="file" @change="pickFile($event)"
                           :name="uploadFieldName"/>
                  </div>
                </div>
                <button class="col mt-2 btn btn-primary">
                  <div v-if="addRiderLoading" class="spinner-border-sm text-success" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <span v-else>Upload file</span>
                </button>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-5" v-if="'Files' in form.sections">
      <h4 class="text-uppercase">{{ form.sections['Files'].name }}</h4>
      <div class="d-flex justify-content-between">
        <div>
          <div class="col-9" v-html="form.sections['Files'].message">
          </div>
          <p>
            File size limit is 10MB, for larger files please save the link to the file (Dropbox, Google drive, etc).
          </p>
        </div>
        <div class="">
          <div class="subtitle2 border rounded border-primary p-2">
            DEADLINE: {{ artist.other_files_deadline | formatDate }}
          </div>
        </div>
      </div>
      <div class="card p-3 my-2">
        <div v-for="file in artist.files" v-bind:key="'rider_files-'+file.id"
             class="mb-3 d-flex justify-content-between">
          <div class="">
            <div class="item-title mb-2">Name</div>
            <div><a :href="file.url" target="_blank">{{ file.filename }}</a></div>
          </div>
          <div v-if="filesAllowed && !artist.form_submitted" class="d-flex align-items-end">
            <button @click="removeFile(file.id)" class="btn btn-primary">
              <Icon name="trash" width="15" height="15"/>
            </button>
          </div>
        </div>
        <div class="mt-3" v-if="filesAllowed && !artist.form_submitted">
          <div class="row">
            <div class="col">
              <form v-on:submit.prevent="uploadFile">
                <div class="item-title mb-2">
                  Choose file
                </div>
                <div class="row">
                  <div>
                    <input id="file-upload2" type="file" ref="other_file" @change="pickFileFile($event)"
                           :name="uploadFieldNameFile"/>
                  </div>
                </div>
                <button class="col mt-2 btn btn-primary">
                  <div v-if="addFileLoading" class="spinner-border-sm text-success" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <span v-else>Upload file</span>
                </button>
              </form>
            </div>
            Or
            <div class="col">
              <div class="item-title mb-2">
                Add link to file Dropbox, Google drive, etc
              </div>
              <div class="row">
                <div>
                  <input type="text"
                         v-model="linkToFile"/>
                </div>
              </div>
              <button class="btn btn-primary mt-2" @click="addFileLink()">Add link</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-5" v-if="'Message' in form.sections">
      <h4 class="text-uppercase">{{ form.sections['Message'].name }}</h4>
      <div class="d-flex justify-content-between">
        <div class="col-9" v-html="form.sections['Message'].message">
        </div>
        <div>

        </div>
      </div>
      <div class="card p-3 my-2">
        <textarea :disabled="!changingComment || artist.form_submitted" v-model="artist.message"></textarea>
      </div>
      <div class="d-flex justify-content-start">
        <button v-if="!changingComment" @click="changingComment = true" class="me-2 btn btn-primary">Change comment</button>
        <button v-if="changingComment && !artist.form_submitted" class="btn btn-primary" @click="saveMessage()">Save comment</button>
      </div>
    </div>

    <div class="mt-5">
      <h4 class="text-uppercase">Users</h4>
      <div class="d-flex justify-content-between">
        <div class="col-9">
          Add or replace users who can edit and view your data. Max 3 users.
        </div>
      </div>
      <div class="card p-3 my-2">
        <template v-for="user in artist.users">
          <div class="row" v-bind:key="'user1-'+user.id">
            <div class="item-title mb-2 col">Name</div>
            <div class="item-title mb-2 col">E-mail</div>
            <div class="item-title col"></div>
          </div>
          <div class="row" v-bind:key="'user2-'+user.id">
            <div class="col"><input type="text" class="form-control" :disabled="editingUser!==user.id" v-model="user.name"/></div>
            <div class="col"><input type="text" class="form-control" :disabled="editingUser!==user.id" v-model="user.e_mail"></div>
            <div v-if="!artist.form_submitted && editingUser!==user.id" class="col d-flex align-items-end">
              <button class="btn btn-primary me-2" @click="toggleEditingUser(user.id)">
                <Icon name="edit" width="15" height="15"/>
              </button>
              <button class="btn btn-primary" @click="removeUser(user.id)">
                <Icon name="trash" width="15" height="15"/>
              </button>
            </div>
            <div v-else-if="!artist.form_submitted" class="col d-flex align-items-end">
              <button class="btn btn-primary me-2" @click="saveEditedUser(user.id)">
                <Icon name="check" width="15" height="15"/>
              </button>
              <button class="btn btn-primary" @click="cancelEditingUser(user.id)">
                <Icon name="close" width="15" height="15"/>
              </button>
            </div>
            <div class="col text-end">
              <button v-if="!user.password_sent" @click="sendPasswordEmail(user.id)" class="btn btn-primary">Send password e-mail</button>
            </div>
          </div>
        </template>
        <div v-if="showAddUser" class="d-flex justify-content-between">
          <div class="">
            <div class="item-title mb-2">Name</div>
            <div><input type="text" class="form-control" v-model="userName"/></div>
          </div>
          <div class="">
            <div class="item-title mb-2">E-mail</div>
            <input class="form-control" type="email" v-model="userEmail"/>
          </div>
          <div class="d-flex align-items-end">
            <div class="btn btn-primary invisible">Btn</div>
            <button class="btn btn-primary" @click="addUser()">
              <Icon name="check" width="15" height="15"/>
            </button>
          </div>
        </div>
        <div class="mt-3" v-if="!artist.form_submitted && artist.users.length < 3">
          <button v-if="!showAddUser" class="btn btn-primary mt-2 " @click="showAddUser = true">Add
            user
          </button>
          <button v-if="showAddUser" class="btn btn-primary mt-2 " @click="closeAddUser()">
            <Icon name="close" width="15" height="15"/>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import Icon from "@/components/Icon";
import store from "@/store";
import {
  addArtistContact, addArtistCrew, addArtistGuest,
  addArtistPerformer, addArtistUser,
  removeArtistContact, removeArtistCrew, removeArtistGuest, removeArtistPerformer, removeArtistUser,
  removeFile, saveArtistMessage, saveFileLink, saveRiderLink, sendPasswordEmail,
  updateEditedContact, updateEditedCrew, updateEditedGuest, updateEditedPerformer, updateEditedUser
} from "@/artists_api";
import Button from "@/components/Button";

export default {
  name: "ArtistData",
  props: ["artist", "form", "artistId", "event", "eventId"],
  components: {Button, Icon},
  data() {
    return {
      editingContact: null,
      showAddContact: false,
      contactPhone: '',
      contactEmail: '',
      contactJobTitle: '',
      contactName: '',
      editedContactPhone: '',
      editedContactEmail: '',
      editedContactJobTitle: '',
      editedContactName: '',
      showAddPerformer: false,
      performerName: '',
      performerEmail: '',
      performerPhone: '',
      performerJobTitle: '',
      editedPerformerName: '',
      editedPerformerEmail: '',
      editedPerformerPhone: '',
      editedPerformerJobTitle: '',
      editingPerformer: null,
      editingCrew: null,
      editedCrewName: '',
      editedCrewEmail: '',
      editedCrewPhone: '',
      editedCrewJobTitle: '',
      showAddCrew: false,
      crewName: '',
      crewEmail: '',
      crewPhone: '',
      crewJobTitle: '',
      guestlistName: '',
      guestlistEmail: '',
      guestlistVia: '',
      editingGuestlist: null,
      editedGuestlistName: '',
      editedGuestlistVia: '',
      editedGuestlistPhone: '',
      showAddGuestlist: false,
      showUploadRider: false,
      linkToRider: '',
      linkToFile: '',
      editingUser: null,
      editedUserName: '',
      editedUserEmail: '',
      userEmail: '',
      userName: '',
      showAddUser: false,
      uploadFieldName: '',
      uploadFieldNameFile: '',
      contactsAllowed: true,
      performersAllowed: true,
      crewAllowed: true,
      guestlistAllowed: true,
      filesAllowed: true,
      ridersAllowed: true,

      addFileLoading: false,
      addRiderLoading: false,
      addContactLoading: false,
      addCrewLoading: false,
      addPerformerLoading: false,
      addGuestLoading: false,

      changingComment: false,
    }
  },
  mounted() {
    if (this.artist.contacts.length === 0)
      this.showAddContact = true
    if (this.artist.performers.length === 0)
      this.showAddPerformer = true
    if (this.artist.crew.length === 0)
      this.showAddCrew = true
    if (this.artist.guestlist.length === 0)
      this.showAddGuestlist = true

    if (dayjs(this.artist.contact_deadline) < dayjs()) {
      this.contactsAllowed = false
    }
    if (dayjs(this.artist.crew_deadline) < dayjs()) {
      this.crewAllowed = false
    }
    if (dayjs(this.artist.performers_deadline) < dayjs()) {
      this.performersAllowed = false
    }
    if (dayjs(this.artist.guestlist_deadline) < dayjs()) {
      this.guestlistAllowed = false
    }
    if (dayjs(this.artist.rider_deadline) < dayjs()) {
      this.ridersAllowed = false
    }
    if (dayjs(this.artist.other_files_deadline) < dayjs()) {
      this.filesAllowed = false
    }
  },
  methods: {
    async uploadRiderFile() {
      const formData = new FormData()
      console.log(this.$refs.file)
      formData.append('rider-file', this.$refs.file.files[0])
      formData.append('fileType', 'rider')
      const headers = {
        "Authorization": "Bearer " + store.jwt(),
        "Accept": "application/json"
      };
      this.addRiderLoading = true

      fetch(`/api/event/${this.eventId}/artists/${this.artistId}/rider_files`, {
        method: 'POST',
        headers: headers,
        body: formData
      }).then((response) => response.json())
        .then((data) => {
          this.artist.rider_files.push(data)
        }).finally(() => this.addRiderLoading = false)
      store.addAlert("success", "Rider added", true)
      this.closeAddRider()
    },
    async uploadFile() {
      const formData = new FormData()
      console.log(this.$refs.other_file)
      formData.append('other-file', this.$refs.other_file.files[0])
      formData.append('fileType', 'rider')
      const headers = {
        "Authorization": "Bearer " + store.jwt(),
        "Accept": "application/json"
      };
      this.addFileLoading = true
      fetch(`/api/event/${this.eventId}/artists/${this.artistId}/files`, {
        method: 'POST',
        headers: headers,
        body: formData
      }).then((response) => response.json())
        .then((data) => {
          this.artist.files.push(data)
        })
      .finally(() => {
        this.addFileLoading = false
        store.addAlert("success", "File added", true)
        })

      this.closeAddRider()
    },
    async addRiderLink() {
      let result = await saveRiderLink(this.eventId, this.artistId, {'url': this.linkToRider})
      this.linkToRider = ''
      this.artist.rider_files.push(result)
      store.addAlert("success", "Rider added", true)
    },
    async addFileLink() {
      let result = await saveFileLink(this.eventId, this.artistId, {'url': this.linkToFile})
      this.linkToFile = ''
      this.artist.files.push(result)
    },
    async removeFile(fileId) {
      await removeFile(this.eventId, this.artistId, fileId)
      this.artist.files = this.artist.files.filter(c => c.id !== fileId)
    },
    pickFile(e) {
      // https://blog.openreplay.com/building-a-custom-file-upload-component-for-vue/
      // Check if file is selected

      let input = this.$refs.rider_file
      let file = input.files
      if (e.target.files && e.target.files[0]) {
        file = e.target.files[0]
        this.uploadFieldName = file.name
      }
      if (file && file[0]) {
        let reader = new FileReader
        reader.onload = e => {
          this.previewImage = e.target.result
        }
        reader.readAsDataURL(file[0])
        this.$emit('input', file[0])
      }
    },
    pickFileFile(e) {
      // https://blog.openreplay.com/building-a-custom-file-upload-component-for-vue/
      // Check if file is selected


      let input = this.$refs.other_file
      let file = input.files
      if (e.target.files && e.target.files[0]) {
        file = e.target.files[0]
        this.uploadFieldNameFile = file.name
      }
      if (file && file[0]) {
        let reader = new FileReader
        reader.onload = e => {
          this.previewImage = e.target.result
        }
        reader.readAsDataURL(file[0])
        this.$emit('input', file[0])
      }
    },
    async addContact() {
      this.addContactLoading = true
      const added = await addArtistContact(this.eventId, this.artistId, {
        'name': this.contactName,
        'job_title': this.contactJobTitle,
        'phone': this.contactPhone,
        'email': this.contactEmail
      }).finally(this.addContactLoading = false)
      this.contactName = ''
      this.contactJobTitle = ''
      this.contactPhone = ''
      this.contactEmail = ''
      this.artist.contacts.push(added)
      this.closeAddContact()
      store.addAlert("success", "Contact added", true)
    },
    toggleEditingContact(id) {
      let a = this.artist.contacts.filter(c => c.id === id)
      let c = a[0]
      if (this.editingContact == null) {
        this.editingContact = id
        this.editedContactEmail = c.email
        this.editedContactName = c.name
        this.editedContactJobTitle = c.job_title
        this.editedContactPhone = c.phone
      } else {
        this.editingContact = null
      }
    },
    cancelEditingContact(id) {
      let a = this.artist.contacts.filter(c => c.id === id)
      let c = a[0]
      c.email = this.editedContactEmail
      c.name = this.editedContactName
      c.job_title = this.editedContactJobTitle
      c.phone = this.editedContactPhone
      this.editingContact = null
    },
    async removeContact(id) {
      await removeArtistContact(this.eventId, this.artistId, id)
      this.artist.contacts = this.artist.contacts.filter(c => c.id !== id)
    },
    async saveEditedContact(id) {
      let index = this.artist.contacts.findIndex(c => c.id === id)
      let c = await updateEditedContact(this.eventId, this.artistId, id, {
        'name': this.artist.contacts[index].name,
        'job_title': this.artist.contacts[index].job_title,
        'email': this.artist.contacts[index].email,
        'phone': this.artist.contacts[index].phone,
      })

      this.toggleEditingContact(id)
    },
    async addPerformer() {
      this.addPerformerLoading = true
      const added = await addArtistPerformer(this.eventId, this.artistId, {
        'name': this.performerName,
        'job_title': this.performerJobTitle,
        'phone': this.performerPhone,
        'email': this.performerEmail
      }).finally(() => this.addPerformerLoading = false)
      this.artist.performers.push(added)
      this.closeAddPerformer()
      store.addAlert("success", "Performer added", true)
    },
    cancelEditingPerformer(id) {
      let a = this.artist.performers.filter(c => c.id === id)
      let c = a[0]
      c.name = this.editedPerformerName
      c.job_title = this.editedPerformerJobTitle
      c.email = this.editedPerformerEmail
      c.phone = this.editedPerformerPhone
      this.editingPerformer = null
    },
    toggleEditingPerformer(id) {
      let a = this.artist.performers.filter(c => c.id === id)
      let c = a[0]
      if (this.editingPerformer === null) {
        this.editingPerformer = id
        this.editedPerformerEmail = c.email
        this.editedPerformerName = c.name
        this.editedPerformerJobTitle = c.job_title
        this.editedPerformerPhone = c.phone
      } else {
        this.editingPerformer = null
      }
    },
    async removePerformer(id) {
      await removeArtistPerformer(this.eventId, this.artistId, id)
      this.artist.performers = this.artist.performers.filter(c => c.id !== id)
    },
    async saveEditedPerformer(id) {
      let index = this.artist.performers.findIndex(c => c.id === id)
      let c = await updateEditedPerformer(this.eventId, this.artistId, id, {
        'name': this.artist.performers[index].name,
        'job_title': this.artist.performers[index].job_title,
        'email': this.artist.performers[index].email,
        'phone': this.artist.performers[index].phone,
      })

      this.toggleEditingPerformer(id)
    },
    cancelEditingCrew(id) {
      let a = this.artist.crew.filter(c => c.id === id)
      let c = a[0]
      c.name = this.editedCrewName
      c.job_title = this.editedCrewJobTitle
      c.email = this.editedCrewEmail
      c.phone = this.editedCrewPhone
      this.editingCrew = null
    },
    toggleEditingCrew(id) {
      let a = this.artist.crew.filter(c => c.id === id)
      let c = a[0]
      if (this.editingCrew == null) {
        this.editingCrew = id
        this.editedCrewEmail = c.email
        this.editedCrewName = c.name
        this.editedCrewJobTitle = c.job_title
        this.editedCrewPhone = c.phone
      } else {
        this.editingCrew = null
      }
    },
    async removeCrew(id) {
      await removeArtistCrew(this.eventId, this.artistId, id)
      this.artist.crew = this.artist.crew.filter(c => c.id !== id)
    },
    async saveEditedCrew(id) {
      let index = this.artist.crew.findIndex(c => c.id === id)
      let c = await updateEditedCrew(this.eventId, this.artistId, id, {
        'name': this.artist.crew[index].name,
        'job_title': this.artist.crew[index].job_title,
        'email': this.artist.crew[index].email,
        'phone': this.artist.crew[index].phone,
      })

      this.toggleEditingCrew(id)
    },
    async addCrew() {
      this.addCrewLoading = true
      const added = await addArtistCrew(this.eventId, this.artistId, {
        'name': this.crewName,
        'job_title': this.crewJobTitle,
        'phone': this.crewPhone,
        'email': this.crewEmail
      }).finally(() => this.addCrewLoading = false)
      store.addAlert("success", "Crew added", true)
      this.artist.crew.push(added)
      this.closeAddCrew()
    },
    cancelEditingGuestlist(id) {
      let a = this.artist.guestlist.filter(c => c.id === id)
      let c = a[0]
      c.name = this.editedGuestlistName
      c.via = this.editedGuestlistVia
      this.editingGuestlist = null
    },
    toggleEditingGuestlist(id) {
      let a = this.artist.guestlist.filter(c => c.id === id)
      let c = a[0]
      if (this.editingGuestlist == null) {
        this.editingGuestlist = id
        this.editedGuestlistName = c.email
        this.editedGuestlistVia = c.via
      } else {
        this.editingGuestlist = null
      }
    },
    async removeGuest(id) {
      await removeArtistGuest(this.eventId, this.artistId, id)
      this.artist.guestlist = this.artist.guestlist.filter(c => c.id !== id)
    },
    async saveEditedGuestlist(id) {
      let index = this.artist.guestlist.findIndex(c => c.id === id)
      let c = await updateEditedGuest(this.eventId, this.artistId, id, {
        'name': this.artist.guestlist[index].name,
        'via': this.artist.guestlist[index].via
      })

      this.toggleEditingGuestlist(id)
    },
    async addGuest() {
      this.addGuestLoading = true
      const added = await addArtistGuest(this.eventId, this.artistId, {
        'name': this.guestlistName,
        'via': this.guestlistVia,
      })
      this.addGuestLoading = false
      this.artist.guestlist.push(added)
      store.addAlert("success", "Guest added", true)
      this.closeAddGuestlist()
    },
    async saveMessage() {
      this.changingComment = false
      await saveArtistMessage(this.eventId, this.artistId, {'message': this.artist.message})
      store.addAlert("success", "Comment saved", true)
    },
    async addUser() {
      const added = await addArtistUser(this.eventId, this.artistId, {
        'name': this.userName,
        'e_mail': this.userEmail
      })
      this.userName = ''
      this.userEmail = ''
      this.artist.users.push(added)
      store.addAlert("success", "User added", true)
      this.closeAddUser()
    },
    toggleEditingUser(id) {
      let a = this.artist.users.filter(c => c.id === id)
      let c = a[0]
      if (this.editingUser == null) {
        this.editingUser = id
        this.editedUserName = c.name
        this.editedUserEmail = c.e_mail
      } else {
        this.editingUser = null
      }
    },
    cancelEditingUser(id) {
      let a = this.artist.users.filter(c => c.id === id)
      let c = a[0]
      c.name = this.editedUserName
      c.e_mail = this.editedUserEmail
      this.editingUser = null
    },
    async removeUser(id) {
      await removeArtistUser(this.eventId, this.artistId, id)
      this.artist.users = this.artist.users.filter(c => c.id !== id)
    },
    async saveEditedUser(id) {
      let index = this.artist.users.findIndex(c => c.id === id)
      let c = await updateEditedUser(this.eventId, this.artistId, id, {
        'name': this.artist.users[index].name,
        'e_mail': this.artist.users[index].e_mail
      })

      this.toggleEditingUser(id)
    },
    closeAddUser() {
      this.showAddUser = false
      this.userName = ''
      this.userEmail = ''
      this.userPassword = ''
      this.userPassword2 = ''
    },
    closeAddContact() {
      this.showAddContact = false
      this.contactName = ''
      this.contactJobTitle = ''
      this.contactEmail = ''
      this.contactPhone = ''
    },
    closeAddPerformer() {
      this.showAddPerformer = false
      this.performerName = ''
      this.performerJobTitle = ''
      this.performerEmail = ''
      this.performerPhone = ''
    },
    closeAddCrew() {
      this.showAddCrew = false
      this.crewName = ''
      this.crewJobTitle = ''
      this.crewEmail = ''
      this.crewPhone = ''
    },
    closeAddGuestlist() {
      this.showAddGuestlist = false
      this.guestlistName = ''
      this.guestlistVia = ''
    },
    closeAddRider() {
      this.showUploadRider = false
      this.$refs.rider_file = null
    },
    sendPasswordEmail(userId) {
      sendPasswordEmail(this.eventId, this.artistId, userId).then(() => {
        let u = this.artist.users.find(u => u.id === userId)
        u.password_sent = true
      })
      store.addAlert("success", "E-mail sent", true)
    }
  }
}
</script>
