<template>
  <div>
    <Navbar/>
    <div class="container-fluid">
      <button type="button" class="btn btn-primary">
        <router-link :to="{name: 'AddEvent' }" class="nav-top-link text-underline-none text-light">
          <span>Add event</span>
        </router-link>
      </button>
      <EventItem v-for="event in events" :event="event" v-bind:key="event.id"/>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import store from '../store'
import Navbar from '@/components/Navbar.vue'
import EventItem from '@/components/EventItem.vue'
import {getAllEvents, getEvents} from "@/api";

export default {
  name: 'AllEvents',
  components: {
    Navbar,
    EventItem
  },
  data() {
    return {
      events: []
    }
  },
  async mounted() {
    //const headers = {
    //  "Authorization": "Bearer " + store.jwt()
    //}

    // const result = await axios.get("/api/events", { headers })

    const result = getAllEvents().then(result => this.events = result)
    //this.events = result
  }
}
</script>
