<template>
  <div class="top-0 position-fixed end-0 toasts-container mt-1 me-1">
    <div :class="'alert alert-dismissible slide-in-top py-1 alert-' + toast.type" :key="toast.id"
         v-for="toast of state.toastMessages" role="alert" aria-live="assertive" aria-atomic="true">
      <div class="d-flex">
        <div class="toast-body">
          <div class="small" v-if="toast.title" v-html="toast.title"></div>
        </div>
        <button @click="dismissAlert(toast)" type="button" class="py-2 btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../store'

export default {
  name: 'Toasts',
  data() {
    return {
      state: store.state
    }
  },
  methods: {
    dismissAlert(toast) {
      store.dismissAlert(toast)
    }
  }
}
</script>
