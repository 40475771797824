<template>
  <div>
    <Navbar :event="event" :eventId="eventId" currentPage="Artists"/>
    <ArtistsMenu :eventId="eventId" active="form" :event="event" v-if="event">
      <div v-if="form && event" class="d-flex flex-row mt-3">
        <div class="flex-grow-1">
          <ArtistBreadcrumbs>
            <li class="breadcrumb-item">
              <router-link :to="{name: 'AdvancingForms', params: { id: event.id }}"
                           class="text-disabled text-decoration-none">
                Forms
              </router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">{{ form.name }}</li>
          </ArtistBreadcrumbs>
        </div>
      </div>
      <div v-if="form">
        <div class="clearfix">
          <div class="float-start">
            <div v-if="!editingFormName">
              <h1 class="float-start">{{ form.name }}</h1>
              <button class="ms-3 mt-4 btn btn-outline-primary float-end" @click="editFormName">
                <Icon name="edit" width="15" height="15"/>
              </button>
            </div>
            <div v-if="editingFormName">
              <input class="float-start" type="text" v-model="editingFormName"/>
              <button class="ms-2 btn btn-outline-primary" @click="saveFormName()">
                <Icon name="check" width="15" height="15"/>
              </button>
            </div>
          </div>
          <button class="mt-3 btn btn-primary float-end" @click="showForm()">VIEW FORM</button>
        </div>
        <br/>

        <h4>DEADLINES</h4>
        <div>
          <input type="date" v-model="deadline"/>
          <button @click="setToAll()" class="ms-2 btn btn-primary">Set to all sections</button>
        </div>
        <table v-if="form" class="table table-bordered mt-3">
          <thead>
          <tr class="border-top-0">
            <td class="subtitle2 border-0 w-50">Section</td>
            <td class="subtitle2 border-0 w-50">Name</td>
            <td class="subtitle2 border-0 w-25">Deadline</td>
            <th class="subtitle2 border-0 w-25">Actions</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(section, index) in form.sections">
            <tr v-bind:key="'section-' + section.id">
              <td>{{ sectionType(section.section_type) }}</td>
              <td>{{ section.name }}</td>
              <td><input type="date" v-if="!['Intro', 'Comments', 'Message'].includes(section.section_type)"
                         v-model="section.deadline"/></td>
              <td>
                <button class="btn btn-secondary" @click="openSection(index)">
                  <Icon name="edit" width="15" height="15"/>
                </button>
              </td>
            </tr>
            <tr v-bind:key="'section-edit-'+section.id" v-if="editingId === index">
              <td colspan="3">
                <div class="row">
                  <div class="item-title">
                    Name
                  </div>
                  <div>
                    <div class="row">
                      <div class="col-auto">
                        <input type="text" class="form-control pe-0" v-model="editingName"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="item-title">
                    Description
                  </div>
                  <div>
                    <div class="row">
                      <div class="col-auto w-100">
                        <div>
                          <el-tiptap v-model="editingText" :extensions="extensions"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div>
                  <button class="btn btn-primary" @click="saveText(editingId)">Save</button>
                  <br/>
                  <button class="mt-2 btn btn-secondary" @click="cancelEditing(editingId)">Cancel</button>
                </div>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
        <button class="btn btn-primary" @click="saveChanges()">Save changes</button>
      </div>
    </ArtistsMenu>
    <Modal v-if="form" v-bind:show-modal.sync="showingForm" :modal-class="'modal-full-screen modal-lg modal-dialog-centered'">
      <div class="modal-content">
        <div class="modal-body">
          <div class="modal-content p-2 overflow-auto">
            <div v-if="'Intro' in form.sections_dict.sections" class="mt-4">
              <h4 class="text-uppercase">{{ form.sections_dict.sections['Intro'].name }}</h4>
              <div class="d-flex justify-content-between">
                <div class="" v-html="form.sections_dict.sections['Intro'].message">
                </div>
              </div>
            </div>

            <div class="mt-5" v-if="'Contacts' in form.sections_dict.sections">
              <h4 class="text-uppercase">{{ form.sections_dict.sections['Contacts'].name }}</h4>
              <div class="d-flex justify-content-between">
                <div v-html="form.sections_dict.sections['Contacts'].message">
                </div>
              </div>
            </div>

            <div class="mt-5" v-if="'Performers' in form.sections_dict.sections">
              <h4 class="text-uppercase">{{ form.sections_dict.sections['Performers'].name }}</h4>
              <div class="d-flex p-0 m-0 justify-content-between">
                <div class="col-9" v-html="form.sections_dict.sections['Performers'].message">
                </div>
              </div>
            </div>

            <div class="mt-5" v-if="'Crew' in form.sections_dict.sections">
              <h4 class="text-uppercase">{{ form.sections_dict.sections['Crew'].name }}</h4>
              <div class="d-flex justify-content-between">
                <div class="col-9" v-html="form.sections_dict.sections['Crew'].message">
                </div>
              </div>
            </div>

            <div class="mt-5" v-if="'Guestlist' in form.sections_dict.sections">
              <h4 class="text-uppercase">{{ form.sections_dict.sections['Guestlist'].name }}</h4>
              <div class="d-flex justify-content-between">
                <div class="col-9" v-html="form.sections_dict.sections['Guestlist'].message">
                </div>
              </div>
            </div>

            <div class="mt-5" v-if="'Riders' in form.sections_dict.sections">
              <h4 class="text-uppercase">{{ form.sections_dict.sections['Riders'].name }}</h4>
              <div class="d-flex justify-content-between">
                <div>
                  <div class="col-9" v-html="form.sections_dict.sections['Riders'].message">
                  </div>
                  <p>
                    File size is limited to 10MB.
                  </p>
                </div>
              </div>
            </div>

            <div class="mt-5" v-if="'Files' in form.sections_dict.sections">
              <h4 class="text-uppercase">{{ form.sections_dict.sections['Files'].name }}</h4>
              <div class="d-flex justify-content-between">
                <div>
                  <div class="col-9" v-html="form.sections_dict.sections['Files'].message">
                  </div>
                  <p>
                    File size limit is 10MB, for larger files please save the link to the file (Dropbox, Google drive, etc).
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="showingForm = false">
            Close
          </button>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import Navbar from '@/components/Navbar.vue'
import {getAdvancingForm, getEvent, getEventData, saveFormTemplates} from "@/api";
import ArtistsMenu from "@/components/ArtistsMenu";
import ArtistBreadcrumbs from "@/components/ArtistBreadcrumbs";
import Icon from "@/components/Icon";
import {
  // necessary extensions
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Underline,
  Italic,
  Strike,
  ListItem,
  BulletList,
  OrderedList,
} from 'element-tiptap';
import store from "@/store";
import Modal from "@/components/Modal";

export default {
  name: 'AdvancingForm',
  components: {
    Navbar, ArtistBreadcrumbs, ArtistsMenu, Icon, Modal
  },
  props: ['eventId', 'formId'],
  data() {
    return {
      form: null,
      event: null,
      general_deadline: null,
      active: 'template',
      deadline: null,
      editedFormName: '',
      editingText: '',
      editingId: null,
      formName: '',
      editingFormName: null,
      showingForm: false,
      extensions: [
        new Doc(),
        new Text(),
        new Paragraph(),
        new Heading({level: 5}),
        new Bold({bubble: true}), // render command-button in bubble menu.
        new Underline({bubble: true, menubar: false}), // render command-button in bubble menu but not in menubar.
        new Italic(),
        new Strike(),
        new ListItem(),
        new BulletList(),
        new OrderedList(),
      ],
    }
  },
  async mounted() {
    this.form = await getAdvancingForm(this.eventId, this.formId)
    this.event = await getEventData(this.eventId)
  },
  methods: {
    sectionType(type) {
      switch (type) {
        case 'BackstageGuests':
          return 'Guestlist'
        default:
          return type
      }
    },
    saveText(index) {
      this.form.sections[index].message = this.editingText
      this.form.sections[index].name = this.editingName
      this.editingId = null
    },
    cancelEditing(index) {
      this.editingText = ''
      this.editingId = null
    },
    openSection(index) {
      this.editingText = this.form.sections[index].message
      this.editingName = this.form.sections[index].name
      this.editingId = index
    },
    setToAll() {
      for (let section of this.form.sections) {
        section.deadline = this.deadline
      }
    },
    async saveChanges() {
      store.addAlert("success", "Form updated", true)
      await saveFormTemplates(this.eventId, this.formId, this.form)
    },
    editFormName() {
      this.editingFormName = this.form.name
    },
    saveFormName() {
      this.form.name = this.editingFormName
      this.editingFormName = null
    },
    showForm() {
      this.showingForm = true
    }
  },
}
</script>
