<template>
  <div>
    <Navbar :event="event" :eventId="eventId" currentPage="Artists"/>
    <ArtistsMenu :eventId="eventId" active="accomodation" :event="event" v-if="event">
      <div v-if="event" class="d-flex flex-row mt-3">
        <div class="flex-grow-1">
          <Breadcrumbs :event="event" currentPageName="Accomodation"/>
        </div>
      </div>

      <h1>Add a new hotel</h1>
      <div class="card elevation-16 mb-3">
        <div class="card-body">
          <form v-on:submit.prevent="addHotel">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <div class="item-title mb-2">
                    Name
                  </div>
                  <div class="row">
                    <div class="col-md-8">
                      <input type="text" class="mb-2 form-control pe-0" v-model="name" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="item-title mb-2">
                    Address
                  </div>
                  <div class="row">
                    <div class="col-md-8">
                      <input  type="text" class="mb-2 form-control pe-0" v-model="address" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="item-title mb-2">
                    Check in
                  </div>
                  <div class="row">
                    <div class="col-md-8">
                      <input type="text" class="mb-2 form-control pe-0" v-model="checkIn" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="item-title mb-2">
                    Check out
                  </div>
                  <div class="row">
                    <div class="col-md-8">
                      <input type="text" class="mb-2 form-control pe-0" v-model="checkOut" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="item-title mb-2">
                    Breakfast
                  </div>
                  <div class="row">
                    <div class="col-md-8">
                      <input type="text" class="mb-2 form-control pe-0" v-model="breakfast" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <input type="submit" class="btn btn-primary" value="Add hotel"/>
              </div>
            </div>
          </form>
        </div>
      </div>

    </ArtistsMenu>
  </div>
</template>

<script>
import ArtistsMenu from "@/components/ArtistsMenu";
import {addHotel, getEventHotels} from "@/artists_api";
import store from "@/store";
import {getEventData} from "@/api";
import Breadcrumbs from "@/components/Breadcrumbs";
import Navbar from "@/components/Navbar";

export default {
  name: "AddHotel",
  components: {
    ArtistsMenu, Breadcrumbs, Navbar
  },
  props: ['eventId'],
  data() {
    return {
      checkOut: '',
      checkIn: '',
      name: '',
      address: '',
      breakfast: '',
      event: null,
    }
  },
  async mounted() {
    this.event = await getEventData(this.eventId)
    this.hotels = await getEventHotels(this.eventId)
  },
  methods: {
    addHotel() {

      addHotel(this.eventId, {name: this.name, address: this.address, breakfast: this.breakfast, checkIn: this.checkIn, checkOut: this.checkOut}).then(() =>
      {
        this.$router.replace({name: 'ArtistsAccomodation', params: {eventId: this.eventId}})
        store.addAlert('success', 'Hotel added', true)
      })
    }
  },
}
</script>
